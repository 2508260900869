import React, {useEffect} from "react";
import "./OurServices.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import serviceOverview1 from "./../../Images/service-overview-1.png";
import serviceOverview2 from "./../../Images/service-overview-2.png";
import serviceOverview3 from "./../../Images/service-overview-3.png";
import serviceOverview4 from "./../../Images/service-overview-4.png";
import serviceOverview5 from "./../../Images/service-overview-5.png";
import serviceOverview6 from "./../../Images/service-overview-6.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
const OurServices = () =>{
    useEffect(() => {
        AOS.init({
            once:true,
            disable: function() {
            var maxWidth = 992;
            return window.innerWidth < maxWidth;
            }
         });
    })
    return(
        <>  
            <div className="services-main-overview">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="main-header no-space-head">
                                <h2>Our Services</h2>
                                <p>CMOTS Internet Technologies Pvt. Ltd. offers a rich repertoire of software services to its clients seeking customized software solutions. Be it complete office automation or database driven solutions, CMOTS Internet Technologies Pvt. Ltd. is the one stop solution center for all your software requirements. We provide you with customized software at competitive prices with free training and support.</p>
                            </div>
                            <div className="overview-box-main">
                            <div className="overview-box-inner" data-aos="fade-up" data-aos-duration="1000">
                                    <h3>Market Data Provider</h3>
                                    <p>We are market data providing company for Indian Capital market.</p>
                                    <img src={serviceOverview5} alt="services" className="img-fluid" />
                                    <Link to="/services/market-data-provider" className="common-blue-btn">Know More</Link>
                                </div>
                                <div className="overview-box-inner" data-aos="fade-up" data-aos-duration="1000">
                                    <h3>Mobile Development</h3>
                                    <p>At CMOTS, delve into unparalleled mobile development.</p>
                                    <img src={serviceOverview2} alt="services" className="img-fluid" />
                                    <Link to="/services/mobile-development" className="common-blue-btn">Know More</Link>
                                </div>
                            <div className="overview-box-inner" data-aos="fade-up" data-aos-duration="1000">
                                    <h3>Customized Software Development</h3>
                                    <p>CMOTS specializes in tailored software development, enhancing operational efficiency and customer retention.</p>
                                    <img src={serviceOverview4} alt="services" className="img-fluid" />
                                    <Link to="/services/customized-software-development" className="common-blue-btn">Know More</Link>
                                </div>
                                <div className="overview-box-inner" data-aos="fade-up" data-aos-duration="1000">
                                    <h3>Website Design & Development</h3>
                                    <p>At CMOTS, our website design and development services are tailor-made, emphasizing professionalism and engagement.</p>
                                    <img src={serviceOverview1} alt="services" className="img-fluid" />
                                    <Link to="/services/website-design-and-development" className="common-blue-btn">Know More</Link>
                                </div>

                                <div className="overview-box-inner" data-aos="fade-up" data-aos-duration="1000">
                                    <h3>Web Hosting</h3>
                                    <p>Connect your content to visitors seamlessly on high-performance Windows servers with web hosting.</p>
                                    <img src={serviceOverview3} alt="services" className="img-fluid" />
                                    <Link to="/services/website-hosting" className="common-blue-btn">Know More</Link>
                                </div>
                               
                                <div className="overview-box-inner" data-aos="fade-up" data-aos-duration="1000">
                                    <h3>Resource Staffing</h3>
                                    <p>Connect your content to visitors seamlessly on high-performance Windows servers with web hosting.</p>
                                    <img src={serviceOverview6} alt="services" className="img-fluid" />
                                    <Link to="/services/resource-staffing" className="common-blue-btn">Know More</Link>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default OurServices;