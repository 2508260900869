import React, { useEffect } from 'react';
import "./../AllProducts.css";
import AllProductsBanner from "../AllProductsBanner/AllProductsBanner";
import SalesCRMBreadCrum from "./SalesCRMBreadCrum/SalesCRMBreadCrum"
import SalesCRMbody from "./SalesCRMbody/SalesCRMbody"
import SalesCRMFeatures from "./SalesCRMFeatures/SalesCRMFeatures"

const SalesCRM = (props) => {
    useEffect(() => {
        window.scroll(0,0)
    })
    return (
    <>
    <AllProductsBanner heightPass={props.height} />
    <SalesCRMBreadCrum />
    <SalesCRMbody />
    <SalesCRMFeatures />
    </>
    );
}

export default SalesCRM;