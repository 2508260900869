import React, { useEffect } from "react";
import FeedbackFormBanner from "./FeedbackFormBanner/FeedbackFormBanner";
import FeedbackFormBody from "./FeedbackFormBody/FeedbackFormBody";
import "./FeedbackForm.css";
import { Col, Container, Row } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
const FeedbackForm = (props) => {
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return (
        <>
            <FeedbackFormBanner heightPass={props.height}/>
            <FeedbackFormBody/>
        </>
    )
}

export default FeedbackForm;