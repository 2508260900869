import { React,  useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Col, Container, Row } from "react-bootstrap";

const DisclamerBanner = (props) => {
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
    return(
        <>
            <div className="about-banner-sc contact-banner" style={{paddingTop: props.heightPass}}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="banner-name">
                                <h3>Disclaimer</h3>
                            </div>
                            <h2 className="banner-description"> 
                            Understand our website's usage terms <br />  and limitations through our clear and <br /> concise disclaimer.
                            </h2>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default DisclamerBanner;