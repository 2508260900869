import {React, useEffect} from "react";
import "./AboutBanner.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Col, Container, Row } from "react-bootstrap";

const AboutBanner = (props) =>{
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
    return(
        <>
         
            <div className="about-banner-sc" style={{paddingTop: props.heightPass}} >
                <Container>
                    <Row>
                        <Col>
                            <div className="banner-name">
                                <h3>About Us</h3>
                            </div>
                            <h2 className="banner-description">Discover our story, where creativity <br/>shapes success. Learn how we<br/> innovate and create impact together</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default AboutBanner;