import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getApiUrl } from "../../../../App";

import AOS from 'aos';
import 'aos/dist/aos.css'

function APIFeatures() {
    const[data, setData] = useState("")
    const[getAPIDataFeedImg, setAPIDataFeedImg] = useState()
    const setApiUrl = useContext(getApiUrl);
useEffect(() =>{
    fetch(`${setApiUrl}/ProductsInner`)
    .then((response) => response.json())
    .then((data) => {
        setData(data.data)
        setAPIDataFeedImg(data.data[0].image)
    })

}, [setApiUrl])
useEffect(() => {
    AOS.init({
        once:'true',
        disable: function() {
        var maxWidth = 992;
        return window.innerWidth < maxWidth;
        }
     });
} , [])
    return (
        <>
            <Container className="container-blue">
                <Row>
                    <Col lg={6} sm={12}>
                        <img src={getAPIDataFeedImg} alt="products" className="img-fluid" />
                    </Col>
                    <Col lg={6} sm={12} className="products-features" data-aos="fade-left" data-aos-duration="800">
                        <h3>Features</h3>
                        
                        {data && data.slice(0,1).map(({Features1, Features2, Features3,Features4, Features5, Features6, Features7, Features8,Features9},index) => (
                            <ul className="service-main-box" key={index}>
                            <li>{Features1}</li>
                            <li>{Features2}</li>
                            <li>{Features3}</li>
                            <li>{Features4}</li>
                            <li>{Features5}</li>
                            <li>{Features6}</li>
                            <li>{Features7}</li>
                            <li>{Features8} </li>
                            <li>{Features9} </li>
                            </ul>
                        ))}                    
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default APIFeatures;