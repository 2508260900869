import {React , useEffect} from "react";
import "./Technologies.css";
import { Col, Container, Row } from "react-bootstrap";
import design1 from "./../../Images/design1.png";
import design2 from "./../../Images/design2.png";
import design3 from "./../../Images/design3.png";
import design4 from "./../../Images/design4.png";
import frontend1 from "./../../Images/frontend1.png";
import frontend2 from "./../../Images/frontend2.png";
import frontend3 from "./../../Images/frontend3.png";
import frontend4 from "./../../Images/frontend4.png";
import frontend5 from "./../../Images/frontend5.png";
import frontend6 from "./../../Images/frontend6.png";
import backend1 from "./../../Images/backend1.png";
import backend2 from "./../../Images/backend2.png";
import backend3 from "./../../Images/backend3.png";
import backend4 from "./../../Images/backend4.png";
import backend5 from "./../../Images/backend5.png";
import mobile1 from "./../../Images/mobile1.png";
import mobile2 from "./../../Images/mobile2.png";
import mobile3 from "./../../Images/mobile3.png";
import mobile4 from "./../../Images/mobile4.png";
import database1 from "./../../Images/database1.png";
import database2 from "./../../Images/database2.png";
import database3 from "./../../Images/database3.png";
import database4 from "./../../Images/database4.png";
import desktop1 from "./../../Images/desktop1.png";
import desktop2 from "./../../Images/desktop2.png";
import desktop3 from "./../../Images/desktop3.png";
import {LiaKeyboard} from "react-icons/lia";
import {BsBagCheckFill , BsPeopleFill} from "react-icons/bs";
import AOS from 'aos';
import 'aos/dist/aos.css';
const Technologies = () =>{
    useEffect(() => {
        AOS.init({
            once:'true',
            disable: function() {
            var maxWidth = 992;
            return window.innerWidth < maxWidth;
          }
       });
    } , [])
    return(
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="main-header no-space-head">
                            <h2>Technologies We Work With</h2>
                            <p>Some core tools & technolgy we use to drive the creation of efficient and user-friendly digital solutions for a <br/>seamless online experience</p>
                        </div>
                    </Col>
                    <Col lg={6} md={12} xs={12} >
                        <div className="tech-main">
                            <div data-aos="fade-right"   data-aos-duration="800">
                                <p>Design</p>
                                <ul>
                                    <li><img src={design1} alt="design" className="img-fluid" /></li>
                                    <li><img src={design2} alt="design" className="img-fluid" /></li>
                                    <li><img src={design3} alt="design" className="img-fluid" /></li>
                                    <li><img src={design4} alt="design" className="img-fluid" /></li>
                                </ul>
                            </div>
                            <div data-aos="fade-right"   data-aos-duration="1000">
                                <p>Frontend Development</p>
                                <ul>
                                    <li><img src={frontend1} alt="FrontEnd" className="img-fluid" /></li>
                                    <li><img src={frontend2} alt="FrontEnd" className="img-fluid" />
                                        <div className="onhover-content">
                                            <div className="count-sc">
                                                <label>Practice</label>
                                                <LiaKeyboard /><span>19 years</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Projects</label>
                                                <BsBagCheckFill /><span>200+</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Workforce</label>
                                                <BsPeopleFill /><span>50+</span>
                                            </div>
                                            <p>CMOTS uses JavaScript’s versatile ecosystem of frameworks to create dynamic and interactive user experience in web and mobile apps.</p>
                                        </div>
                                    </li>
                                    <li><img src={frontend3} alt="FrontEnd" className="img-fluid" />
                                        
                                    </li>
                                    <li><img src={frontend4} alt="FrontEnd" className="img-fluid" />
                                        <div className="onhover-content">
                                            <div className="count-sc">
                                                <label>Practice</label>
                                                <LiaKeyboard /><span>6 years</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Projects</label>
                                                <BsBagCheckFill /><span>5+</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Workforce</label>
                                                <BsPeopleFill /><span>3+</span>
                                            </div>
                                            {/* <p>CMOTS leverages code reusability Angular is notable for to create large-scale apps. We chose Angular for a banking app with 3M+ users.</p> */}
                                        </div>
                                    </li>
                                    <li><img src={frontend5} alt="FrontEnd" className="img-fluid" />
                                    <div className="onhover-content native-cnt">
                                            <div className="count-sc">
                                                <label>Practice</label>
                                                <LiaKeyboard /><span>4 years</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Projects</label>
                                                <BsBagCheckFill /><span>10+</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Workforce</label>
                                                <BsPeopleFill /><span>10+</span>
                                            </div>
                                            {/* <p>CMOTS reduces up to 50% of project costs and time by creating cross-platform apps that run smoothly on web, Android and iOS.</p> */}
                                        </div>
                                    </li>
                                    <li><img src={frontend6} alt="FrontEnd" className="img-fluid" /></li>
                                </ul>
                            </div>
                            <div data-aos="fade-right"   data-aos-duration="1300">
                                <p>Backend Development</p>
                                <ul>
                                    <li><img src={backend1} alt="BackEnd" className="img-fluid" />
                                        <div className="onhover-content">
                                            <div className="count-sc">
                                                <label>Practice</label>
                                                <LiaKeyboard /><span>19 years</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Projects</label>
                                                <BsBagCheckFill /><span>200+</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Workforce</label>
                                                <BsPeopleFill /><span>50+</span>
                                            </div>
                                            <p>Our .NET developers can build sustainable and high-performing apps up to 2x faster due to outstanding .NET proficiency and high productivity.</p>
                                        </div>
                                    </li>
                                    <li><img src={backend2} alt="BackEnd" className="img-fluid" />
                                        <div className="onhover-content">
                                            <div className="count-sc">
                                                <label>Practice</label>
                                                <LiaKeyboard /><span>5 years</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Projects</label>
                                                <BsBagCheckFill /><span>3+</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Workforce</label>
                                                <BsPeopleFill /><span>5+</span>
                                            </div>
                                            <p>Python developers and data scientists excel at building general-purpose Python apps, big data and IoT platforms, AI and ML-based apps, and BI solutions.</p>
                                        </div>
                                    </li>
                                    <li><img src={backend3} alt="BackEnd" className="img-fluid" />
                                        <div className="onhover-content">
                                            <div className="count-sc">
                                                <label>Practice</label>
                                                <LiaKeyboard /><span>10 years</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Projects</label>
                                                <BsBagCheckFill /><span>10+</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Workforce</label>
                                                <BsPeopleFill /><span>20+</span>
                                            </div>
                                            {/* <p>CMOTS  delivers cloud-native, real-time web and mobile apps, web servers, and custom APIs ~1.5–2x faster than other software developers.</p> */}
                                        </div>
                                    </li>
                                    <li><img src={backend4} alt="BackEnd" className="img-fluid" />
                                        <div className="onhover-content">
                                            <div className="count-sc">
                                                <label>Practice</label>
                                                <LiaKeyboard /><span>14 years</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Projects</label>
                                                <BsBagCheckFill /><span>17+</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Workforce</label>
                                                <BsPeopleFill /><span>30+</span>
                                            </div>
                                            {/* <p> PHP developers helped to build Viber. Their recent projects: an IoT fleet management solution used by 2,000+ corporate clients and an award-winning remote patient monitoring solution.</p> */}
                                        </div>
                                    </li>
                                    <li><img src={backend5} alt="BackEnd" className="img-fluid" /></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} xs={12} >
                    <div className="tech-main right-tech-main">
                            <div data-aos="fade-left"   data-aos-duration="800">
                                <p>Mobile Development</p>
                                <ul>
                                    <li><img src={mobile1} alt="Mobile" className="img-fluid" />
                                        <div className="onhover-content">
                                            <div className="count-sc">
                                                <label>Practice</label>
                                                <LiaKeyboard /><span>7 years</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Projects</label>
                                                <BsBagCheckFill /><span>5+</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Workforce</label>
                                                <BsPeopleFill /><span>10+</span>
                                            </div>
                                            {/* <p>CMOTS achieves 20–50% cost reduction for android projects due to excellent self-management and Agile skills of the team. The quality is never compromised — our iOS apps are highly rated.</p> */}
                                        </div>
                                    </li>
                                    <li><img src={mobile2} alt="Mobile" className="img-fluid" />
                                        {/* <div className="onhover-content">                                           
                                            <p>CMOTS achieves 20–50% cost reduction for iOS projects due to excellent self-management and Agile skills of the team. The quality is never compromised — our iOS apps are highly rated.</p>
                                        </div> */}
                                    </li>
                                    <li><img src={mobile3} alt="Mobile" className="img-fluid" />
                                        <div className="onhover-content">
                                            <div className="count-sc">
                                                <label>Practice</label>
                                                <LiaKeyboard /><span>3 years</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Projects</label>
                                                <BsBagCheckFill /><span>2+</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Workforce</label>
                                                <BsPeopleFill /><span>4+</span>
                                            </div>
                                            {/* <p>CMOTS will save you from double or even triple expenses associated with platform-specific coding by creating cross-platform apps in Flutter.</p> */}
                                        </div>
                                    </li>
                                    <li><img src={mobile4} alt="Mobile" className="img-fluid" />
                                        <div className="onhover-content native-cnt">
                                            <div className="count-sc">
                                                <label>Practice</label>
                                                <LiaKeyboard /><span>4 years</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Projects</label>
                                                <BsBagCheckFill /><span>10+</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Workforce</label>
                                                <BsPeopleFill /><span>10+</span>
                                            </div>
                                            {/* <p>CMOTS reduces up to 50% of project costs and time by creating cross-platform apps that run smoothly on web, Android and iOS.</p> */}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div data-aos="fade-left"   data-aos-duration="1000">
                                <p>Relational databases / data storages</p>
                                <ul>
                                    <li><img src={database1} alt="Database" className="img-fluid" />
                                        {/* <div className="onhover-content">
                                            <p>Our Microsoft SQL Server-based projects include a BI solution for 10 healthcare centers, the world’s largest PLM software, and an automated underwriting system for the global commercial insurance carrier.</p>
                                        </div> */}
                                    </li>
                                    <li><img src={database2} alt="Database" className="img-fluid" />
                                        {/* <div className="onhover-content">
                                            <p>CMOTS has used PostgreSQL in an IoT fleet management solution that supports 50+ customers with 500+ IoT devices. We’ve also helped a fintech startup promptly launch a top-flight BNPL product based on PostgreSQL.</p>
                                        </div> */}
                                    </li>
                                    <li><img src={database3} alt="Database" className="img-fluid" />
                                        {/* <div className="onhover-content">
                                            <p>We’ve implemented MySQL for Viber, an instant messenger with 1B+ users, and an award-winning remote patient monitoring software.</p>
                                        </div> */}
                                    </li>
                                    <li><img src={database4} alt="Database" className="img-fluid" />
                                        {/* <div className="onhover-content">
                                            <p>CMOTS  team has implemented Oracle for software products used by GSK and AstraZeneca. We’ve also delivered Oracle-based SCM platform for Auchan, a retail chain with 700 stores.</p>
                                        </div> */}
                                    </li>
                                </ul>
                            </div>
                            <div data-aos="fade-left"   data-aos-duration="1300">
                                <p>Desktop Application Development</p>
                                <ul>
                                    <li><img src={desktop1} alt="Desktop" className="img-fluid" />
                                        <div className="onhover-content">
                                            <div className="count-sc">
                                                <label>Practice</label>
                                                <LiaKeyboard /><span>19 years</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Projects</label>
                                                <BsBagCheckFill /><span>50+</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Workforce</label>
                                                <BsPeopleFill /><span>50+</span>
                                            </div>
                                            <p>Our .NET developers can build sustainable and high-performing apps up to 2x faster due to outstanding .NET proficiency and high productivity.</p>
                                        </div>
                                    </li>
                                    <li><img src={desktop2} alt="Desktop" className="img-fluid" />
                                        {/* <div className="onhover-content">
                                            <div className="count-sc">
                                                <label>Practice</label>
                                                <LiaKeyboard /><span>19 years</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Projects</label>
                                                <BsBagCheckFill /><span>50+</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Workforce</label>
                                                <BsPeopleFill /><span>10+</span>
                                            </div>
                                            <p>Our C# developers created the world’s largest PLM software. Their recent projects: development of SaaS for vCIO services management and underwriting software for a global aviation insurer</p>
                                        </div> */}
                                    </li>
                                    <li><img src={desktop3} alt="Desktop" className="img-fluid" />
                                        <div className="onhover-content">
                                            <div className="count-sc">
                                                <label>Practice</label>
                                                <LiaKeyboard /><span>5 years</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Projects</label>
                                                <BsBagCheckFill /><span>3+</span>
                                            </div>
                                            <div className="count-sc">
                                                <label>Workforce</label>
                                                <BsPeopleFill /><span>5+</span>
                                            </div>
                                            <p>Python developers and data scientists excel at building general-purpose Python apps and BI solutions.</p>
                                        </div>
                                    </li>
                                    <li><img src={backend5} alt="Desktop" className="img-fluid" /></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Technologies;