import {React, useContext, useEffect , useState} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Container, Row, Col } from 'react-bootstrap';
import { getApiUrl } from '../../App';

function DisclamerBodyText() {
    let getData;
    const [data, setData] = useState('')
    const setApiUrl = useContext(getApiUrl);
    
    useEffect(()=> {
        fetch(`${setApiUrl}/Disclaimer`)
        .then((respons) => respons.json())
        .then((data) => {
            getData = data.data;
            setData(getData);
            console.log(getData);
        })
    
        
    }, [setApiUrl])
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
    return (
     <Container>
        <Row>
            <Col xs={12}>
            <div className='PrivacyBodyHeading' data-aos="fade-up"   data-aos-duration="1000"> Disclaimer </div>
            <div className='PrivacyBodyText' data-aos="fade-up"   data-aos-duration="1000"> 
           <p> The contents of <a href='http://www.cmots.com'> http://www.cmots.com </a> are based on information generally available to the public from sources believed to be reliable. No representation is made that it is TIMELY, ACCURATE or COMPLETE</p>
           <p> However, <a href='http://www.cmots.com'> http://www.cmots.com </a> does not guarantee the accuracy, adequacy or completeness of any information and it is not responsible for any errors or omissions or for the results obtained from the use of such information. <a href='http://www.cmots.com'>http://www.cmots.com </a> is also not responsible for any errors in transmission and especially states that it has no financial liability whatsoever to the subscribers / users / transmitters / distributors of this product. </p>
           <p> The information herein, together with all estimates and forecasts, can change without notice. This report does not purport to be a complete solicitation of offers to buy or sell any securities. Visitors to the site are advised to consult experts or study prospectus and other legal offer documents issued by companies before taking any decisions based on information provided in the site.</p>
           <p> Neither <a href='http://www.cmots.com'> http://www.cmots.com </a> nor its Directors or Analysts or Employees accept any liability whatsoever nor do they accept responsibility for any financial consequences arising from the use of the research or information provided herein. </p>
</div> 
            </Col>
        </Row>
     </Container>
    );
}

export default DisclamerBodyText;