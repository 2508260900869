import React, { useState } from "react";
import CareerFormBanner from "./CareerFormBanner/CareerFormBanner";
import JobApplication from "./JobApplication/JobApplication";
import "./CareerForm.css";
import { Col, Container, Row } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';





const CareerForm = (props) => {

    return (
        <>
            <CareerFormBanner heightPass={props.height}/>
            <JobApplication/>
        </>
    )
}

export default CareerForm;