import {React, useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Col, Container, Row } from "react-bootstrap";
import "./CareerBanner.css";
const CareerBanner =(props) =>{
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
    return(
        <>
            <div className="about-banner-sc career-banner-sc" style={{paddingTop: props.heightPass}} >
                <Container>
                    <Row>
                        <Col>
                            <div className="banner-name">
                                <h3>Careers</h3>
                            </div>
                            <h2 className="banner-description">Join Our Team: Where Passion Meets<br/> Profession, Unleash Your Creativity in <br/>a Design-Centric Environment.</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default CareerBanner;