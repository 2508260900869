import React ,{useEffect} from "react";
import "./../AllServices.css";
import AllServicesBanner from "../AllServicesBanner/AllServicesBanner";
import DataProviderBody from "./DataProviderBody/DataProviderBody";
import DataProviderFeatures from "./DataProviderFeatures/DataProviderFeatures";
import DataProviderFaq from "./DataProviderFaq/DataProviderFaq";
const DataProvider = (props) =>{
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <AllServicesBanner heightPass={props.height}/>
            <DataProviderBody />
            <DataProviderFeatures />
            <DataProviderFaq />
        </>
    )
}
export default DataProvider;