import React ,{useEffect, useState, useContext} from "react";
import "./Faq.css";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import AOS from 'aos';
import 'aos/dist/aos.css'
import { getApiUrl } from "../../App";
const Faq = () => {
    const[data, getData] = useState();
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
        AOS.init({
            once:'true',
            disable: function() {
            var maxWidth = 992;
            return window.innerWidth < maxWidth;
            }
         });
    } , [])

useEffect(() => {
    fetch(`${setApiUrl}/FAQOnSoftwareDevelopment`)
    .then((response) => response.json())
    .then((data) => {
        getData(data.data)
        //console.log(data)
    })
}, [])
    return(
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="main-header" data-aos="fade-up" data-aos-duration="800">
                            <h2 className="text-left">FAQ on Software Development</h2>
                        </div>
                        <div className="faq-sc" data-aos="fade-up" data-aos-duration="1000">
                        <Accordion defaultActiveKey="0">
                            {data && data.map(({question, answer},index ) => (
                            <Accordion.Item eventKey={index}>
                            <Accordion.Header>{question}</Accordion.Header>
                            <Accordion.Body>
                            {answer}
                            </Accordion.Body>
                            </Accordion.Item>
                            ))}
                           
                            </Accordion>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Faq;