import {React , useState , useEffect} from "react";
import "./ConnectWithUs.css";
import { Col, Container, Row } from "react-bootstrap";
// import ConnectImg from "./../../Images/connect-img.png";
// import {TbRefresh} from "react-icons/tb";
import AOS from 'aos';
import 'aos/dist/aos.css';

const ConnectWithUs = () => {
    const [iam,setIam] = useState("Corporate");
    const [name, setName] = useState("");
    const [companyname, setcompanynameName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailInput, setEmail] = useState("");
    const [contactnamehandler,setcontactnamehandler] = useState("");
    const [showing , setShowing] = useState(false);
    const [allChecked, setallChecked] = useState([]);
    const [iamError, iAmError] = useState('');
    const [nameError, setNameError] = useState('');
    const [companynameError, setcompanyNameError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [contactnamehandlerError,setcontactnamehandlerError] = useState('');
    const [checkinterestError,setCheckInterestError] = useState('');
    const [data,setData] = useState('');
    const [getcompanyname,setCompanyName] = useState(true);
    const [individualName, setIndividualName] = useState(false);
    const [branch,setBranch] = useState("");
    const [branchError,setbranchError] = useState("")
    const [dataSubmit,setDataSubmit] = useState(false)
    useEffect(() => {
        AOS.init({
            once:'true',
           disable: function() {
           var maxWidth = 768;
           return window.innerWidth < maxWidth;
           }
           
        });
    } , [])

    let nameflag = true, mobileflag = true, emailFlag = true, contactNameFlag = true ,iamFlag = true ,checkBoxInterestFlag = true,companynameflag = true,branchFlag = true;
    // captchFlag = true;
    
    const iamHandler =(e) =>{
        setIam(e.target.value);
        iAmError("");
        iamFlag = true;
        if(e.target.value === "Corporate"){
            setCompanyName(true);
            setIndividualName(false);
        }
        else{
            setCompanyName(false);
            setIndividualName(true);
        }
        setNameError(""); setcompanyNameError(""); setMobileError("");setEmailError(""); setcontactnamehandlerError("");setCheckInterestError("");setName("");setcompanynameName("");
        setMobileNumber(""); setEmail("");setallChecked([]);setcontactnamehandler("");setbranchError("");
        setBranch("");setDataSubmit(false);
    }

    const namehandler = (e) => {
        let nameRegex = /^[a-zA-Z\s]*$/;
        if (nameRegex.test(e.target.value) && (e.target.value.length !== 0)) {
            setName(e.target.value);
            setNameError("");
            nameflag = true;
        }
        else if((e.target.value.length == 0)){
            setName("");
        }
        else {
            // setName("");
            setNameError("Only alphabets are allowed");
            nameflag = false;
        }
    }
    const companynamehandler = (e) =>{
        let nameCRegex = /^[a-zA-Z\s]*$/;
        if (nameCRegex.test(e.target.value) && (e.target.value.length !== 0)) {
            setcompanynameName(e.target.value);
            setcompanyNameError("");
            companynameflag = true;
        }
        else if((e.target.value.length == 0)){
            setcompanynameName("");
        }
        else {
            // setcompanynameName("");
            setcompanyNameError("Only alphabets are allowed");
            companynameflag = false;
        }
    }
    const mobileNumberhandler = (e) => {
        let mobileRegex = /^[0-9\b]+$/;
        if (mobileRegex.test(e.target.value) && (e.target.value.length !== 0)) {
            setMobileNumber(e.target.value);
            setMobileError("");
            mobileflag = true;
        }
        else if((e.target.value.length == 0)){
            setMobileNumber("");
        }
        else {
            // setMobileNumber("");
            setMobileError("Only numbers are allowed");
            mobileflag = false;
        }
    }
    const emailHandler = (e) => {
        setEmail(e.target.value);
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if (emailRegex.test(e.target.value)) {
            setEmailError("");
        }
        else if(e.target.value.length == 0){
            setEmailError("");
        }
        else {
            setEmailError("Please enter valid Email with @")
        }
    }

    const contactnamehandlerChange = (e) =>{
        let ContactnameRegex = /^[a-zA-Z\s]*$/;
        if (ContactnameRegex.test(e.target.value) && (e.target.value.length !== 0)) {
            setcontactnamehandler(e.target.value);
            setcontactnamehandlerError("");
            contactNameFlag = true;
        }
        else {
            setcontactnamehandler("");
            setcontactnamehandlerError("Only alphabets are allowed");
            contactNameFlag = false;
        }
    }

    const interstCheckbox =() =>{
        setShowing(!showing);
    }
    const handleChange = (e) =>{
        if (e.target.checked) {
            setallChecked([...allChecked, e.target.value]);
            checkBoxInterestFlag = true;
            setCheckInterestError("")
        } else {
            setallChecked([...allChecked.filter((item) => item !== e.target.value)]);
            checkBoxInterestFlag = true;
            setCheckInterestError("")
        }
    }

    const branchChangeHandler = (e) =>{
        // setBranch(e.target.value);
        if(e.target.value == "Select location of our branch"){
            setBranch("");
            setbranchError("Please select Branch to contact");
            branchFlag = false;
        }
        else{
            setBranch(e.target.value);
            setbranchError("");
            branchFlag = true;
        }
        
    }
    // const randomString =  Math.random().toString(36).slice(2,8);
    // const [captcha,setCaptcha] = useState(randomString);
    // const [captachaText,setcaptachaText] = useState("");
    // const [captchaError, setcaptchaError] = useState("");
    // const recreateCapcha = () =>{
    //     setCaptcha(Math.random().toString(36).slice(2,8));
    // }
    // const captchaHandler = (e) => {
    //     setcaptachaText(e.target.value);
    //     setcaptchaError("");
    //     captchFlag = true;
    // }
    const handleSubmit = (e) => {
        e.preventDefault();
        let emailRegex;
        if(iam == "Corporate"){
            emailRegex =/^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/;
        }else{
            emailRegex =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  
        }
        if (companyname === "") {
            companynameflag = false;
            setcompanyNameError("Please Enter Company Name");
        }
        if (name === "") {
            nameflag = false;
            setNameError("Please Enter Name");
        }
        if (mobileNumber === "" || mobileNumber.length !== 10) {
            mobileflag = false;
            setMobileError("Please enter 10 digit number");
        }
        if ((emailInput === "" || !emailRegex.test(emailInput)) && iam === "Corporate") {
            emailFlag = false;
            setEmailError("Please enter valid Corporate Email")
        }else if (emailInput === "" || !emailRegex.test(emailInput) && iam == "Individual"){
            emailFlag = false;
            setEmailError("Please enter valid Email")
        }
        if (emailRegex.test(emailInput)) {
            emailFlag = true;
            setEmailError("");
        }
        if(contactnamehandler === ""){
            contactNameFlag = false
            setcontactnamehandlerError("Plese enter contact name")
        }
        if(iam === ""){
            iamFlag = false
            iAmError("Please choose");
        }
        if(allChecked == false){
            checkBoxInterestFlag = false
            setCheckInterestError("Please select your interest");
        }
        if(branch === "" || branch ==="Select location of our branch"){
            branchFlag= false;
            setbranchError("Please select branch to contact");
        }
        // if(captachaText.length === 0){
        //     captchFlag = false;
        //     setcaptchaError("Please enter captcha");
        // }
        // if(captachaText !== captcha && captachaText.length > 0){
        //     captchFlag = false;
        //     setcaptchaError("Captcha not matched");
        // }
        let isFormValid;
        if(iam == "Corporate"){
            isFormValid = iamFlag && companynameflag && mobileflag && emailFlag  && checkBoxInterestFlag &&contactNameFlag && branchFlag;
        }else{
            isFormValid = nameflag  && mobileflag && emailFlag  && iamFlag && checkBoxInterestFlag && companynameflag && branchFlag ;
        }
        // captchFlag && 
        if (isFormValid) {
            let saveForm =
            JSON.stringify({  
                "UserType": iam,
                "NAME": name || contactnamehandler,
                "comp_name" : companyname,
                "txtphone": mobileNumber,
                "txtemail": emailInput,
                //"checkboxInterest" : {...allChecked}
                "ImInterestedIn" : allChecked.join(),
                "branches": branch.toLowerCase(),
                "txtcomment1":"",
                "WdAbtusId":""
            })
            
            fetch(`http://cmots2023apis.cmots.com/api/ContactUsAPI`,{
                method : 'POST',
                body : saveForm,
                headers: {'Content-type': 'application/json; charset=UTF-8'},
            })
            .then((response) => response.json())
            .then(()=>{
                //console.log(data)
                setData(saveForm)
                setDataSubmit(true)
                
            })
            setTimeout(() => {
                setDataSubmit(false);
            }, 8000);
            
            // .then((data) => {
            //     setData(data)
            // })
            //setData(saveForm)
                // console.log(data)
                //updateMyArray((arr) => [...arr,...saveForm]) for copying all object into 1
            
            setName(""); setMobileNumber(""); setEmail(""); setIam("Corporate");setallChecked([]); setcontactnamehandler("");setCompanyName(true);setcompanynameName("");
            setIndividualName(false);setNameError(""); setcompanyNameError(""); setMobileError("");setEmailError(""); setcontactnamehandlerError("");setCheckInterestError("");setBranch("");setbranchError("")
            // setcaptachaText("");setCaptcha(randomString);
            //console.log(allChecked)
        }
    }
    return(
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="main-header">
                            <h2>Connect with us</h2>
                        </div>
                    </Col>
                </Row>
                <Row className="connect-sc">
                    <Col lg={6} md={6} xs={12} data-aos="fade-right" data-aos-duration="2000">
                        <div className="connect-img-box" data-aos="zoom-in" data-aos-duration="800">
                            {/* <img src={ConnectImg} alt="" className="img-fluid" /> */}
                            <div>
                                <div className="main-header">
                                    <h2>Refined Solutions Start Here.</h2>
                                </div>
                                <p>Reach out for personalized assistance. Your journey with us begins by connecting through our dedicated contact channels. Let's collaborate!</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={6} xs={12} data-aos="fade-left" data-aos-duration="2000">
                        
                        <form id="submitForm" className="submit-form" data-aos="fade-left" data-aos-duration="800">
                            <div className="form-field radio-field">
                            <input type="radio" value="Corporate" name="gender" onChange={iamHandler}  checked={iam === 'Corporate'}/> Corporate
                            <input type="radio" value="Individual" name="gender" onChange={iamHandler}  checked={iam === 'Individual'}/>An Individual
                            {/* <input type="text" name="username" autoComplete="off" value={name} onChange={namehandler} /> */}
                            <small>{iamError}</small>
                        </div>
                        {getcompanyname && (
                        <div className="form-field">
                            <input type="text" name="companyname" autoComplete="off" value={companyname} onChange={companynamehandler} placeholder="Company Name"/>
                            <small>{companynameError}</small>
                        </div>
                        )} 
                        {individualName && (
                        <div className="form-field">
                            <input type="text" name="username" autoComplete="off" value={name} onChange={namehandler} placeholder="Name"/>
                            <small>{nameError}</small>
                        </div>
                        )}
                        <div className="form-field">
                            <input type="text" name="mobilenumber" placeholder="Phone no." autoComplete="off" value={mobileNumber} minLength="10" maxLength="10" onChange={mobileNumberhandler} />
                            <small>{mobileError}</small>
                        </div>
                        <div className="form-field">
                            <input type="text" name="email" placeholder="Email Id" autoComplete="off" value={emailInput}
                                onChange={emailHandler} />
                            <small>{emailError}</small>
                        </div>
                        
                        {getcompanyname && (
                        <div className="form-field">
                            <input type="text" name="contactname" autoComplete="off" value={contactnamehandler} onChange={contactnamehandlerChange} placeholder="Company Contact Person"/>
                            <small>{contactnamehandlerError}</small>
                        </div>
                        )} 
                        {individualName && (
                       <div className="form-field">
                            <input type="text" name="companyname" autoComplete="off" value={companyname} onChange={companynamehandler} placeholder="Company Name"/>
                            <small>{companynameError}</small>
                        </div>
                        )}
                        <div className="form-field">
                            <div className="intersert-div" >
                                <div className="checkbox-input-toggle" onClick={interstCheckbox} >I am interested in</div>
                                <small>{checkinterestError}</small>
                                <div style={{display : (showing ? 'block' : 'none')}} className="show-hide-check">
                                    <label>Web Solutions</label>
                                    <div>
                                        <input value = "WebsiteDesign&Development" type="checkbox"  checked={allChecked.includes("WebsiteDesign&Development")}  onChange={handleChange} />
                                        <span> Website Design & Development </span>
                                    </div>
                                    <div>
                                        <input value = "VirtualPortfolioTracker" type="checkbox"  checked={allChecked.includes("VirtualPortfolioTracker")}  onChange={handleChange} />
                                        <span>Virtual Portfolio Tracker</span>
                                    </div>
                                    <div>
                                        <input value = "WealthWraps" type="checkbox"  checked={allChecked.includes("WealthWraps")}  onChange={handleChange} />
                                        <span> WealthWraps </span>
                                    </div>
                                    <div>
                                        <input value = "FinancialTools" type="checkbox"  checked={allChecked.includes("FinancialTools")}  onChange={handleChange} />
                                        <span>  Financial Tools </span>
                                    </div>
                                    <div>
                                        <input value = "ContentManagementSystem" type="checkbox"  checked={allChecked.includes("ContentManagementSystem")}  onChange={handleChange} />
                                        <span> Content Management System </span>
                                    </div>
                                    <div>
                                        <input value = "TechnicalCharts" type="checkbox"  checked={allChecked.includes("TechnicalCharts")}  onChange={handleChange} />
                                        <span>  Technical Charts </span>
                                    </div>
                                    <label>Mobile Solutions</label>
                                    <div>
                                        <input value = "MobileWebsite" type="checkbox"  checked={allChecked.includes("MobileWebsite")}  onChange={handleChange} />
                                        <span> Mobile Website </span>
                                    </div>
                                    <div>
                                        <input value = "MobileApp" type="checkbox"  checked={allChecked.includes("MobileApp")}  onChange={handleChange} />
                                        <span> Mobile App</span>
                                    </div>
                                    <label>Market & Financial Content</label>
                                    <div>
                                        <input value = "RAWData" type="checkbox"  checked={allChecked.includes("RAWData")}  onChange={handleChange} />
                                        <span>RAW Data</span>
                                    </div>
                                    <div>
                                        <input value = "WebServices" type="checkbox"  checked={allChecked.includes("WebServices")}  onChange={handleChange} />
                                        <span>Web Services</span>
                                    </div>
                                    <label>Products</label>
                                    <div>
                                        <input value = "APIDataFeed" type="checkbox"  checked={allChecked.includes("APIDataFeed")}  onChange={handleChange} />
                                        <span> API DataFeed</span>
                                    </div>
                                    <div>
                                        <input value = "WealthWraps" type="checkbox"  checked={allChecked.includes("WealthWraps")}  onChange={handleChange} />
                                        <span>  WealthWraps  </span>
                                    </div>
                                    <div>
                                        <input value = "IFATransact" type="checkbox"  checked={allChecked.includes("IFATransact")}  onChange={handleChange} />
                                        <span> IFA Transact</span>
                                    </div>
                                    <div>
                                        <input value = "CMOTSeKYC" type="checkbox"  checked={allChecked.includes("CMOTSeKYC")}  onChange={handleChange} />
                                        <span> CMOTS eKYC</span>
                                    </div>
                                    <div>
                                        <input value = "CMOTSIPOOnline" type="checkbox"  checked={allChecked.includes("CMOTSIPOOnline")}  onChange={handleChange} />
                                        <span> CMOTS IPO Online</span>
                                    </div>
                                    <div>
                                        <input value = "CMOTSLMS" type="checkbox"  checked={allChecked.includes("CMOTSLMS")}  onChange={handleChange} />
                                        <span> CMOTS LMS</span>
                                    </div>
                                    <div>
                                        <input value = "SalesCRM" type="checkbox"  checked={allChecked.includes("SalesCRM")}  onChange={handleChange} />
                                        <span>Sales CRM</span>
                                    </div>
                                    <div>
                                        <input value = "VirtualPortfolioTracker" type="checkbox"  checked={allChecked.includes("VirtualPortfolioTracker")}  onChange={handleChange} />
                                        <span>Virtual Portfolio Tracker</span>
                                    </div>
                                    <div>
                                        <input value = "IssueTrackingSystem" type="checkbox"  checked={allChecked.includes("IssueTrackingSystem")}  onChange={handleChange} />
                                        <span>Issue Tracking System</span>
                                    </div>
                                    <label>Other Services</label>
                                    <div>
                                        <input value = "WebsiteHosting" type="checkbox"  checked={allChecked.includes("WebsiteHosting")}  onChange={handleChange} />
                                        <span>Website Hosting</span>
                                    </div>
                                    <div>
                                        <input value = "MobileDevelopment" type="checkbox"  checked={allChecked.includes("MobileDevelopment")}  onChange={handleChange} />
                                        <span>Mobile Development</span>
                                    </div>
                                    <div>
                                        <input value = "MarketDataProvider" type="checkbox"  checked={allChecked.includes("MarketDataProvider")}  onChange={handleChange} />
                                        <span>Market Data Provider</span>
                                    </div>
                                    <div>
                                        <input value = "CustomizedSoftwareDevelopment" type="checkbox"  checked={allChecked.includes("CustomizedSoftwareDevelopment")}  onChange={handleChange} />
                                        <span>Customized Software Development</span>
                                    </div>
                                    <div>
                                        <input value = "ResourceStaffing" type="checkbox"  checked={allChecked.includes("ResourceStaffing")}  onChange={handleChange} />
                                        <span>Resource Staffing</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-field">
                            <select onChange={branchChangeHandler} value={branch}>
                                <option value="Select location of our branch">Select location of our branch</option>
                                <option value="Mumbai">Mumbai</option>
                                <option value="Ahmedabad">Ahmedabad</option>
                                <option value="Banglore">Banglore</option>
                                <option value="Chennai">Chennai</option>
                                <option value="Cochin">Cochin</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Hyderabad">Hyderabad</option>
                                <option value="Kolkata">Kolkata</option>
                                <option value="Pune">Pune</option>
                            </select>
                            <small>{branchError}</small>
                        </div>
                        {/* <div className="form-field captch-field">
                            <div>
                                <span>{captcha}</span> 
                                <span onClick={recreateCapcha}><TbRefresh /></span>
                                <input type="text" name="captcha" placeholder="Enter Captcha" autoComplete="off" value={captachaText} onChange={captchaHandler}/>
                                <small>{captchaError}</small>
                            </div>
                        </div> */}
                        <div className="relative-sc">
                        <div className="form-field submit-form-field">
                            <input type="submit" value="Submit" onClick={handleSubmit} className="common-blue-btn"/>
                        </div>
                        {dataSubmit && (<div className="submitted-data">Data submitted Successfully</div>)}
                        </div>
                        {/* <div>{data}</div> */}
                        {/* <div>{JSON.stringify(data)}</div> */}
                    </form>
                    
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ConnectWithUs;