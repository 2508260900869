import React,{useEffect, useState, useContext} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getApiUrl } from "../../../../App";
import AOS from 'aos';
import 'aos/dist/aos.css'

function VirtualPortfolioTrackerbody() {
 const setApiUrl = useContext(getApiUrl);
 const[data, setData] = useState()
useEffect(() => {
    fetch(`${setApiUrl}/ProductsInner`)
    .then((response) => response.json())
    .then((data) => {
        setData(data.data)
    })
}, [setApiUrl])
useEffect(() => {
    AOS.init({
        once:'true',
        disable: function() {
        var maxWidth = 992;
        return window.innerWidth < maxWidth;
        }
     });
} , [])
    return (
      <>
       <Container>
                <Row>
                    <Col xs={12}>
                        {data && data.slice(7,8).map(({FullDescription1, FullDescription2, FullDescription4},index)=> (
                        <>
                        <div className="DiscriptionText" key={index}>
                    <p>{FullDescription1}</p>
                    <p>{FullDescription2}</p>
                    {/* <span>For more information visit our website - <a href="http://salescrm.cmots.com/" target="_blank">Virtual Portfolio Tracker</a></span> */}
                    </div>
                        </>
                        ))}                    
                    </Col>
                </Row>
            </Container>
      </>
    );
}

export default VirtualPortfolioTrackerbody;