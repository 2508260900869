import React ,{useEffect} from "react";
import "./../AllServices.css";
import AllServicesBanner from "../AllServicesBanner/AllServicesBanner";
import ResourceStaffingBody from "./ResourceStaffingBody/ResourceStaffingBody";
// import ResourceStaffingFeatures from "./ResourceStaffingFeatures/ResourceStaffingFeatures";
// import ResourceStaffingFaq from "./ResourceStaffingFaq/ResourceStaffingFaq";
const ResourceStaffing = (props) =>{
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <AllServicesBanner heightPass={props.height}/>
            <ResourceStaffingBody />
            {/* <ResourceStaffingFeatures />
            <ResourceStaffingFaq /> */}
        </>
    )
}
export default ResourceStaffing;