import React ,{useEffect, useState, useContext} from "react";
import "./WhatWeLook.css";
import { Col, Container, Row } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getApiUrl } from "../../App";

const WhatWeLook = () =>{
    const[data, getData] = useState("")
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])

useEffect(() => {
 fetch(`${setApiUrl}/career/what`)
 .then((response) => response.json())
 .then((data) => {
    getData(data.data)
 })
}, [setApiUrl])
    return(
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div class="main-header no-space-head"  data-aos="fade-up" data-aos-duration="800">
                            <h2>What we look for?</h2>
                            <p>Seeking passionate, creative minds to join our team. If you're driven, innovative, and thrive in a <br/> collaborative environment, let's create together</p>
                        </div>
                        <div className="whatwelook-main" >
                            {data && data.map(({subheading, description},index) => (
                                <div key={index}  data-aos="fade-up" data-aos-duration="800">
                                <label>{subheading}</label>
                                <p>{description}</p>
                            </div>
                            ))}

                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default WhatWeLook;