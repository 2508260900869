import {React, useState,useRef, useEffect, useContext} from "react";
import "./Glance.css";
import { Col ,Container, Row} from "react-bootstrap";
import Slider from "react-slick";
import { CiCircleChevLeft } from "react-icons/ci";
import { CiCircleChevRight } from "react-icons/ci";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getApiUrl } from "../../App";
// import glance1 from "./../../Images/glance-1.png";
// import glance2 from "./../../Images/glance-2.png";
// import glance3 from "./../../Images/glance-3.png";


const Glance = () => {
    const slider = useRef();
    const [slideIndex, setSlideIndex] = useState(0);
    const [activeSlide, setActiveSlide] = useState(0);
    const[data, getData] = useState();
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
      
         AOS.init({
            once:'true',
            disable: function() {
            var maxWidth = 992;
            return window.innerWidth < maxWidth;
            }
         });
         fetch(`${setApiUrl}/OurCompany/Cmots`)
        .then(response => response.json())
        .then((data) => 
        {getData(data.data);
      });
    }, [slideIndex,activeSlide,setApiUrl]);

 
    
    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CiCircleChevLeft />,
        nextArrow: <CiCircleChevRight />,
        afterChange: current => setActiveSlide(current)
    };
    const onClick = (index) => {
        setSlideIndex(index)
        slider.current.slickGoTo(index);
    }

   
    return(
        <>
            <Container>
                <Row>
                    <Col>
                        <div className="main-header no-space-head" data-aos="fade-up"   data-aos-duration="1000">
                            <h2 className="text-left">Cmots at a glance</h2>
                        </div>
                        <div className="glance-main" data-aos="fade-up"   data-aos-duration="1000">
                            <ul className="glance-ul">
                                {data && data.map(({heading},index) => (
                                    <li  className={activeSlide  == index ? "active" : ""} onClick={() => onClick(index)}>{heading}</li>
                                    
                                ))}
                                 {/* <li  className={activeSlide  === 0 ? "active" : ""} onClick={() => onClick(0)}>Our Vision</li>
                                <li  className={activeSlide  === 1 ? "active" : ""} onClick={() => onClick(1)}>Our Mission</li>
                                <li  className={activeSlide  === 2 ? "active" : ""} onClick={() => onClick(2)}>Our Culture & Values</li> */}
                            </ul>
                            <div className="glance-slider">
                            <Slider {...settings} ref={slider} className="common-slider">
                                {data && data.map(({srno,heading,description,image},index) => (

                                    <div className="glane-slider-inner" data-index={index} tabindex={index} key={index}>
                                        <div className="glance-content">
                                            <div>
                                                <label>
                                                    <span>{srno}</span> /3
                                                </label>
                                                <div className="glance-content-inner">
                                                    <h3>{heading}</h3>
                                                    <p>{description}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <img src={image} alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                        
                                    </div>
                                ))}
                            </Slider>
                            </div>
                        </div>
                        
                    </Col>
                    
                </Row>

            </Container>
        </>
    )
}

export default Glance;