import React, { useState , useEffect} from "react";
import { AiFillCloseCircle } from "react-icons/ai";

const MobileNav = ({ closeNav }) => {
    const [Menumenu, setMenumenu] = useState('');


const setMenumenuClick = (e,event) =>{
    //console.log(event);
    setMenumenu(e);
    //  let abc = event.currentTarget.className;
    // console.log(abc)
    event.currentTarget.classList.toggle("active")
    
}


    return (
        <>
            <div id="overlay" onClick={() => closeNav(false)}></div>
            <div className="mobile-menu-sc">
                <AiFillCloseCircle onClick={() => closeNav(false)} className="close-icon"/>
                <ul className="mobile-menu-links">
                    <li><a href="/AboutUs">About</a></li>
                    <li onClick={(event) => setMenumenuClick(1,event)} data-value="1" className={Menumenu == 1 ? "active clickmenu" : " clickmenu"}>
                        <a >Services 
                            <ul>
                                <li><a href="/Services">Overview</a> </li>
                                <li><a href="/Services/MarketDataProvider">Market Data Provider</a></li>
                                <li><a href="/Services/MobileDevelopment">Mobile Development</a></li>
                                <li><a href="/Services/CustomizedSoftwareDevelopment">Customized Software Development</a></li>                               
                                <li><a href="/Services/WebsiteDesign&Development">Website Design & Development</a></li>
                                <li><a href="/Services/WebsiteHosting">Website Hosting</a></li>
                                <li><a href="/Services/ResourceStaffing">Resource Staffing</a></li>                                
                            </ul>
                        </a>
                    </li>
                    <li onClick={(event) => setMenumenuClick(2,event)} data-value="2" className={ Menumenu == 2 ? "active clickmenu" : " clickmenu"}>
                        <a >Products
                            <ul>
                                <li><a href="/Products" >Overview</a></li>
                                <li><a href="/Products/APIDataFeed" >API DataFeed</a></li>
                                <li><a href="/Products/WealthWraps">WealthWraps</a></li>
                                <li><a href="/Products/IFATransact">IFA Transact</a></li>
                                <li><a href="/Products/CMOTSeKYC">CMOTS eKYC</a></li>
                                <li><a href="/Products/CMOTSIPOOnline">CMOTS IPO Online</a></li>
                                <li><a href="/Products/CmotsLMS">CMOTS LMS</a></li>
                                <li><a href="/Products/SalesCRM">Sales CRM</a></li>
                                <li><a href="/Products/VirtualPortfolioTracker">Virtual Portfolio Tracker</a></li>
                                <li><a href="/Products/IssueTrackingsystem">Issue Tracking system</a></li>
                            </ul>
                        </a>
                    </li>
                    <li><a href="/ContactUs">Contact Us</a></li>
                </ul>
            </div>
        </>
    )
}

export default MobileNav;