import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ErrorImg from "./../Images/page-not-found.png";
import './ErrorPage.css'

function ErrorPage(props) {
    return (
    <> 
    <Container fluid className="BodyBGcolor">
        <Container>
            <Row>
          <Col className='ImgPadingTop pb-5'>
          <img src={ErrorImg} className='img-fluid d-block mx-auto' />
          </Col>
            </Row>
        </Container>

    </Container>
  
    </>
    );
}

export default ErrorPage;