import React ,{useEffect} from "react";
import "./../AllServices.css";
import AllServicesBanner from "../AllServicesBanner/AllServicesBanner";
import WebsiteHostingBody from "./WebsiteHostingBody/WebsiteHostingBody";
import WebsiteHostingFeatures from "./WebsiteHostingFeatures/WebsiteHostingFeatures";
import WebsiteHostingFaq from "./WebsiteHostingFaq/WebsiteHostingFaq";
const WebsiteHosting = (props) =>{
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <AllServicesBanner heightPass={props.height}/>
            <WebsiteHostingBody />
            <WebsiteHostingFeatures />
            <WebsiteHostingFaq />
        </>
    )
}
export default WebsiteHosting;