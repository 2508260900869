import React , {useEffect, useState, useContext}from "react";
import "./IndustryExperience.css";
import { Col, Container, Row } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getApiUrl } from "../../App";
const IndustryExperience =() =>{
    const [data, getData] = useState()
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
        AOS.init({
            once:'true',
           disable: function() {
           var maxWidth = 992;
           return window.innerWidth < maxWidth;
           }
        });
    } , [])

    useEffect(() => {
fetch(`${setApiUrl}/IndustryExpertise`)
.then((response) => response.json())
.then((data) =>{
    getData(data.data)
})
    }, [setApiUrl])
    return(
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="main-header no-space-head" data-aos="fade-up" data-aos-duration="800">
                            <h2>Industry Expertise</h2>
                            <p>We’ve excelled our experience in a wide range of industries to bring valuable insights and <br/>provide our customers with the truly beneficial solutions.</p>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="industry-sc">
                            {data && data.map(({sector, description,image},index) =>(
                            <div className="industry-sc-inner" key={index}>
                            <img src={image} alt="industry" className="img-fluid" />
                            <h3>{sector}</h3>
                            <p>{description}</p>
                            </div>
                            ))}
                           
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default IndustryExperience;