import React, {useContext, useEffect, useState} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./OurProducts.css";
import { getApiUrl } from "../../App";
const OurProducts = () =>{
    const setApiUrl = useContext(getApiUrl)
    const [data,setData] = useState();
    useEffect(() => {
        AOS.init({
            once:true,
            disable: function() {
            var maxWidth = 992;
            return window.innerWidth < maxWidth;
            }
         });
        fetch(`${setApiUrl}/ProductsInner`)
        .then(response => response.json())
        .then((data) =>{
            setData(data.data);
            console.log(data.data)
        })
    },[setApiUrl])
    return(
        <>  
            <div className="services-main-overview">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="main-header no-space-head">
                                <h2>Our Products</h2>
                                <p>Elevate your business with innovative solutions tailored to your needs. From software applications to advanced automation, our products redefine efficiency and performance for optimal success.</p>
                            </div>
                            <div className="overview-box-main product-overview-box">
                                {data && data.map(({Products,image,FullDescription1,knowmore},index) => (
                                    <div className="overview-box-inner" data-aos="fade-up" data-aos-duration="1000" key={index}>
                                    <h3>{Products}</h3>
                                    <p>{FullDescription1} . </p>
                                    <img src={image} alt="services" className="img-fluid" />
                                    <Link to={knowmore} className="common-blue-btn">Know More</Link>
                                </div>
                                ))}
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default OurProducts;