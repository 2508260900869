import React, { useState } from "react";
import './FeedbackFormBody.css';
import { Col, Container, Row } from "react-bootstrap";
import Backdrop from "../../Images/FeedbackFormBackdrop.png";
import CaptchaResertBtn from "../../Images/CaptchaResetBtn.png";
const FeedbackFormBody = () => {

    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");
    const [email,setEmail] = useState("");
    const [comment,setComment] = useState("");
    const [captcha,setCaptcha] = useState("")
    const [nameError,setNameError] = useState("");
    const [phoneError,setPhoneError] = useState("");
    const [emailError,setEmailError] = useState("");
    const [commentError,setCommentError] = useState("");
    const [captchaError,setCaptchaError] = useState("")
    const [formdata,setFormData] = useState("");
    const [dataSubmit,setDataSubmit] = useState(false)
    let nameFlag= true, mobileFlag = true, emailFlag = true, commentFlag = true,captchFlag = true;

    const nameHandler = (e) =>{
        let nameRegex = /^[a-zA-Z\s]*$/;
        if(nameRegex.test(e.target.value) && (e.target.value.length !== 0)){
            setName(e.target.value);
            setNameError("");
            nameFlag = true;
        }
        else{
            setName("");
            setNameError("Only Alphabets are allowed");
            nameFlag = false;
        }
    }

    const phoneHandler = (e) =>{
        let mobileRegex = /^[0-9\b]+$/;
        if(mobileRegex.test(e.target.value) && (e.target.value.length !== 0)){
            setPhone(e.target.value);
            setPhoneError("");
            mobileFlag = true;
        }
        else{
            setPhone("");
            setPhoneError("Please enter 10 digit number");
            mobileFlag = false;
        }
    }

    const emailHandler = (e) =>{
        setEmail(e.target.value);
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(emailRegex.test(e.target.value)){
            setEmailError("");
        }
        else {
            setEmailError("Please enter valid Email with @")
        }
    }

    const commentHandler = (e) =>{
        let commentRegex = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
        if((commentRegex.test(e.target.value)) && (e.target.value.length !==0)){
            setComment(e.target.value);
            setCommentError("");
            commentFlag= true;
        }else{
            setComment("");
            setCommentError("Please enter comment without special characters.");
            commentFlag = false;
        }
    }

    const genreateCaptcha = () =>{
        let characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let captchaGenerate = "";
        for(let i=0; i< 6 ;i++){
            const randomIndex= Math.floor(Math.random() * characters.length);
            captchaGenerate += characters.charAt(randomIndex);
        }
        return captchaGenerate;
    }

    const randomString = genreateCaptcha();
    const [generateCaptcha, setgenerateCaptcha] = useState(randomString)

    const recreateCaptcha = () =>{
        setgenerateCaptcha(genreateCaptcha);
    }
    const captchaHandler = (e) =>{
        setCaptcha(e.target.value);
        setCaptchaError("");
        captchFlag= true;
    }

    const feedbackFormSubmit =(e) =>{
        e.preventDefault();
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(name === ""){
            nameFlag = false;
            setNameError("Please Enter Name");
        }
        if(phone === "" || phone.length !== 10){
            mobileFlag = false;
            setPhoneError("Please Enter 10 Digit Number")
        }
        if(email === "" ||  !emailRegex.test(email)){
            emailFlag = false;
            setEmailError("Please Enter Valid Email")
        }
        if(comment === ""){
            commentFlag= false;
            setCommentError("Please Enter Comment")
        }
        if(captcha === ""){
            captchFlag = false;
            setCaptchaError("Please Enter Captcha Code")
        }
        if(generateCaptcha !== captcha && captcha.length > 0){
            captchFlag = false;
            setCaptchaError("Captcha not matched")
        }

        let isFormValid = nameFlag && mobileFlag && emailFlag && commentFlag && captchFlag;
        if(isFormValid){

            let feedbackFormData = JSON.stringify({
                "Name": name,
                "EmailID": email,
                "PhoneNumber":phone,
                "Comment":comment
            })

            fetch(`http://cmots2023apis.cmots.com/api/feedbackAPI`,{
                method:'POST',
                body: feedbackFormData,
                headers: {'Content-type': 'application/json; charset=UTF-8'},
            })
            .then(response => response.json())
            .then(() =>{
                setFormData(feedbackFormData);
                setDataSubmit(true)
            })
           
            setTimeout(() => {
                setDataSubmit(false);
            }, 8000);
            // setFormData(feedbackFormData);

            setName("");setPhone("");setEmail("");setComment("");setCaptcha("");
            setNameError("");setPhoneError("");setEmailError("");setCommentError("");setCaptchaError("");
        }
        
        
    }

    return (
        <>
            <div className="job-application-sc" >
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="job-application-container Start">
                                <div className="backdrop">
                                    <img src={Backdrop} className="img-fluid" alt=""></img>
                                </div>
                                <div className="content">
                                    {/* <img src={CareerFormImg} alt="" className="img-fluid" /> */}
                                    <p>
                                    Feedback Form
                                    <span>
                                    Share your experience; your feedback <br/> fuels CMOTS' commitment to continuous <br/> improvement and excellence.
                                    </span>
                                    </p>
                                </div>

                                <div className="job-application-form">
                                    <form onSubmit={feedbackFormSubmit} id="feedBackForm">
                                        <div className="form-field">
                                            <input type="text" name="name" placeholder="Full Name" value={name} onChange={nameHandler} autoComplete="off"></input>
                                            <small>{nameError}</small>
                                        </div>
                                        <div className="form-field">
                                            <input type="text" name="number" placeholder="Phone no." value={phone} minLength="10" maxLength="10" onChange={phoneHandler} autoComplete="off"></input>
                                            <small>{phoneError}</small>
                                        </div>
                                        <div className="form-field">
                                            <input type="text" name="email" placeholder="Email id"  onChange={emailHandler} autoComplete="off" value={email}></input>
                                            <small>{emailError}</small>
                                        </div>
                                        <div className="form-field">
                                            <textarea name="comment" rows={2} placeholder="Comment" onChange={commentHandler} autoComplete="off" value={comment}/>
                                            <small>{commentError}</small>
                                        </div>
                                        <div className="form-field captcha ">
                                            <input type="text" name="captcha" placeholder="Enter Code" onChange={captchaHandler} autoComplete="off" value={captcha}></input>
                                            <div className="captcha-container">
                                                <span>{generateCaptcha}</span>
                                                <img src={CaptchaResertBtn} alt="reGenerate" onClick={recreateCaptcha}></img>
                                            </div>
                                            <small>{captchaError}</small>
                                        </div>
                                        <div className="relative-sc">
                                            <div className="form-field submit-form-field">
                                                <input type="submit" value="Submit"  className="common-blue-btn"></input>
                                            </div>
                                            {dataSubmit && (<div className="submitted-data">Data submitted Successfully</div>)}
                                        </div>
                                        {/* <div>{formdata}</div> */}
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default FeedbackFormBody;