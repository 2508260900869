import React, { useEffect } from 'react';
import "./../AllProducts.css";
import AllProductsBanner from "../AllProductsBanner/AllProductsBanner";
import IssueTrackingSystemBreadCrum from './IssueTrackingSystemBreadCrum/IssueTrackingSystemBreadCrum'
import IssueTrackingSystembody from "./IssueTrackingSystembody/IssueTrackingSystembody"
import IssueTrackingSystemFeatures from './IssueTrackingSystemFeatures/IssueTrackingSystemFeatures'

const IssueTrackingSystem = (props) => {
    useEffect(() => {
        window.scroll(0,0)
    })
    return (
     <>
     <AllProductsBanner heightPass={props.height} />
     <IssueTrackingSystemBreadCrum />
     <IssueTrackingSystembody />
     <IssueTrackingSystemFeatures />
     </>
    );
}

export default IssueTrackingSystem;