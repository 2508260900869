import React ,{useEffect} from "react";
import "./OurClients.css";
import { Col, Container, Row } from "react-bootstrap";
import company1 from "./../../Images/company1.png";
import company2 from "./../../Images/company2.png";
import company3 from "./../../Images/IDBI_DirectLogo.png";
import company4 from "./../../Images/YesSecurities.png";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
const OurClients = () => {
    const settings = {
        className: "slider",
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        arrows: true,
        responsive: [
          {
            breakpoint: 1365,
            settings: {
              slidesToShow: 4,
  
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
  
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
  
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
  
            }
          },
        ]
        };
      useEffect(() => {
          AOS.init({
            once:'true',
            disable: function() {
            var maxWidth = 992;
            return window.innerWidth < maxWidth;
            }
         });
      } , [])
    return(
        <>
        <div className="client-sc">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="main-header white-head">
                            <h2>Our Clients</h2>
                            <p>Explore the success stories and experiences of those who have trusted us on <br/>their digital journey.</p>
                        </div>
                        <div className="client-main-box">
                            <Slider {...settings}>
                                <div className="client-inner" data-aos="zoom-in"   data-aos-duration="1000">
                                    <img src={company1} alt="company" className="img-fluid" />
                                    {/* <h4>AXISDIRECT</h4>
                                    <p>Provided financial data, news and content along with an attractive design and enriching graphics</p> */}
                                </div>
                                <div className="client-inner" data-aos="zoom-in"  data-aos-duration="1000">
                                    <img src={company2} alt="company" className="img-fluid" />
                                    {/* <h4>HDFC Securities</h4>
                                    <p>Provided financial data, news and content along with an attractive design and enriching graphics</p> */}
                                </div>
                                <div className="client-inner" data-aos="zoom-in"  data-aos-duration="1000">
                                    <img src={company3} alt="company" className="img-fluid" />
                                    {/* <h4>Kotak Securities</h4>
                                    <p>Provided financial data, news and content along with an attractive design and enriching graphics</p> */}
                                </div>
                                <div className="client-inner" data-aos="zoom-in"  data-aos-duration="1000">
                                    <img src={company4} alt="company" className="img-fluid" />
                                    {/* <h4>Kotak Securities</h4>
                                    <p>Provided financial data, news and content along with an attractive design and enriching graphics</p> */}
                                </div>
                        
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}
export default OurClients