import React, { useState , useEffect, useContext} from "react";
import "./ProductsWeDeliver.css";
import { Col, Container, Row } from "react-bootstrap";
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import IFATransact from "./../../Images/IFATransact.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {getApiUrl } from "../../App";

const ProductsWeDeliver = () =>{
    const [value, setValue] = useState(0);
    const [data, getData] = useState("");
    const setApiUrl = useContext(getApiUrl);
    const handleChange = (event, newValue) => {
    setValue(newValue);
    };
    useEffect(() => {
        AOS.init({
            once:true,
            disable: function() {
            var maxWidth = 992;
            return window.innerWidth < maxWidth;
            }
         });
         fetch(`${setApiUrl}/ProductsWeDeliver`)
         .then(response => response.json())
         .then((data) => {
            getData(data.data)
            //console.log(data)
         })
    } , [setApiUrl])
    return(
        <>  
            <div className="solution-sc" data-aos="fade-up" data-aos-duration="1000">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="main-header no-space-head">
                                <h2>Products We Deliver</h2>
                                <p>We IT-enable all kinds of B2B, B2C interactions and internal operations.</p>
                            </div>
                            <Box>
                                <TabContext value={value}>
                                    <TabList onChange={handleChange}  aria-label="services"  variant="scrollable"
                                    scrollButtons="auto" > 
                                        {data && data.map(({products},index) => (
                                            <Tab label={products} value={index} />
                                        ))}
                                        
                                       
                                    </TabList>
                                    {data && data.map(({description,products, image, knowMore},index) => (
                                            <TabPanel value={index}>
                                            <div className="services-main">
                                                <div>
                                                    <img src={image} alt="" className="img-fluid" />
                                                </div>
                                                <div className="services-content">
                                                    <h5>{products}</h5>
                                                    <p>{description}</p>
                                                    <a href={knowMore} className="common-blue-btn">Know More</a>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    ))}
                                    

                                </TabContext>
                            </Box>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ProductsWeDeliver;