import React, { useEffect, useState } from "react";
import { Container ,Row, Col} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const CMOTSekycBreadCrum = () =>{
    const[optionValue,setOptionValue] = useState('')
    const [path,setPath] = useState();    
    const navigate = useNavigate()
    const serviceChangeHandler = (e) =>{
            setOptionValue(e.target.value);
    }
    useEffect(() => {
        var abc = window.location.pathname.split('/')[2];
        setPath(abc);
        if(optionValue){
            navigate(`/products/${optionValue}`)
        }
        else{

        }
    },[optionValue])
    return (
        <>
        <Container>
                <Row>
                    <Col>
                        <div className="breadcrum-list">
                        <div className="main-header">
                            <h2>CMOTS e-KYC</h2>
                        </div>
                        <div className="product-list">
                            <select onChange={serviceChangeHandler} value={path}>
                                <option value="api-datafeed" >API DataFeed</option>
                                <option value="wealthwraps" >WealthWraps</option>
                                <option value="ifa-transact" >IFA Transact</option>
                                <option value="cmots-ekyc">CMOTS eKYC</option>
                                <option value="ipo-online">CMOTS IPO Online</option>
                                <option value="cmots-lms">CMOTS LMS</option>
                                <option value="sales-crm">Sales CRM</option>
                                <option value="virtual-portfolio-tracker">Virtual Portfolio Tracker</option>
                                <option value="issue-tracking">Issue Tracking system</option>
                            </select>
                        </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            </>
    );
}

export default CMOTSekycBreadCrum;