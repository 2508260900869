import React, { useState } from "react";
import "./JobApplication.css";
import { Col, Container, Row } from "react-bootstrap";
import Backdrop from "../../Images/job-application-backdrop.png";
import UploadImg from "../../Images/uplode-img.png";
import { FaCaretDown } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FileUploader } from "react-drag-drop-files";




const JobApplication = (props) => {
    // const [dropValue, setDropValue] = useState("default");


    const fileTypes = ["JPEG", "PNG", "PDF", "DOC"];

    const [name, setName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailInput, setEmail] = useState("");
    const [selectLocation, setSelectLocation] = useState("");
    const [selectRole, setSelectRole] = useState("")
    const [fileUplode, setfileUplode] = useState(null);


    const [nameError, setNameError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [selectLocationError, setSelectLocationError] = useState('');
    const [selectRoleError, setSelectRoleError] = useState('');
    const [fileUplodeError, setFileUplodeError] = useState('')
    const [file, setfile] = useState(null);
    const [fileName, setFileName] = useState("")
    const [data, setData] = useState('');
    const [dataSubmit,setDataSubmit] = useState(false)






    let nameflag = true, mobileflag = true, emailFlag = true, locationFlag = true, roleFlag = true, fileUplodeFlag = true;




    const namehandler = (e) => {
        let nameRegex = /^[a-zA-Z\s]*$/;
        if (nameRegex.test(e.target.value) && (e.target.value.length !== 0)) {
            setName(e.target.value);
            setNameError("");
            nameflag = true;
        }else if((e.target.value.length == 0)){
            setName("")
        }
        else {
            // setName("");
            setNameError("Only alphabets are allowed");
            nameflag = false;
        }
    }


    const mobileNumberhandler = (e) => {
        let mobileRegex = /^[0-9\b]+$/;
        if (mobileRegex.test(e.target.value) && (e.target.value.length !== 0)) {
            setMobileNumber(e.target.value);
            setMobileError("");
            mobileflag = true;
        }
        else if((e.target.value.length == 0)){
            setMobileNumber("")
        }
        else {
            // setMobileNumber("");
            setMobileError("Only numbers are allowed");
            mobileflag = false;
        }
    }

    const emailHandler = (e) => {
        setEmail(e.target.value);
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRegex.test(e.target.value)) {
            setEmailError("");
            emailFlag = true;
        }
        else {
            setEmailError("Please enter valid Email with @")
            emailFlag = false;
        }
    }


    const handlerLocationSelect = (e) => {

        if(e.target.value === ""){
            setSelectLocation("");
            setSelectLocationError("Please Select Location");
            locationFlag = false;
        }
        else{
            setSelectLocation(e.target.value);
            setSelectLocationError("");
            locationFlag = true;
        }
    }
    const handlerRoleSelect = (e) => {
        if(e.target.value === ""){
            setSelectRole("");
            setSelectRoleError("Please Select Your Role");
            roleFlag = false;
        }
        else{
            setSelectRole(e.target.value);
            setSelectRoleError("");
            roleFlag = true;
        }
    }


    // const handleChange = (file) => {
    //     setfile(file)
    //     setFileUplodeError('')
    //     setFileName(file.name);
    //     fileUplodeFlag = true;
    // };




    const handleSubmit = (e) => {
        e.preventDefault();
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (name === "") {
            nameflag = false;
            setNameError("Please Enter Your Name");
        }
        if (mobileNumber === "" || mobileNumber.length !== 10) {
            mobileflag = false;
            setMobileError("Please enter 10 digit number");
        }
        if (selectLocation === "") {
            locationFlag = false;
            setSelectLocationError("Please Select Location")
        }
        if (selectRole === "") {
            roleFlag = false;
            setSelectRoleError("Please Select Your Role")
        }
        // if (file == null) {
        //     fileUplodeFlag = false;
        //     setFileUplodeError("Please Uplode Your Resume")
        // }
        if (emailInput === "" || !emailRegex.test(emailInput)) {
            emailFlag = false;
            setEmailError("Please enter valid Email")
        }
        if (emailRegex.test(emailInput)) {
            emailFlag = true;
            setEmailError("");
        }


        // if(captachaText.length === 0){
        //     captchFlag = false;
        //     setcaptchaError("Please enter captcha");
        // }
        // if(captachaText !== captcha && captachaText.length > 0){
        //     captchFlag = false;
        //     setcaptchaError("Captcha not matched");
        // }

        let isFormValid = nameflag && mobileflag && emailFlag && locationFlag && roleFlag && fileUplodeFlag; ; 
    
        // captchFlag && 
        if (isFormValid) {
            let saveForm =
            JSON.stringify({
                    "FullName": name,
                    "Phoneno": mobileNumber,
                    "Emailid": emailInput,
                    "RoleYouapplyingfor": selectRole,
                    "applyLocation": selectLocation,
                    "DropYourResumeHere": "null",
                })
            fetch(`http://cmots2023apis.cmots.com/api/CareerFormAPI`,{
                method : 'POST',
                body : saveForm,
                headers: {'Content-type': 'application/json; charset=UTF-8'},
            })
            .then((response) => response.json())
            .then(()=>{
                //console.log(data)
                setData(saveForm)
                setDataSubmit(true)
                
            })
            setTimeout(() => {
                setDataSubmit(false);
            }, 8000);
           

            setName(""); setMobileNumber(""); setEmail(""); setSelectRole("");  setSelectLocation("");setfileUplode("");
           
        }
    }


    return (
        <>
            <div className="job-application-sc" style={{ paddingTop: props.height }} >

                <Container>
                    {/* <Row>
                        <Col xs={12}>
                            <div className="main-header">
                                <h2>Job Application</h2>
                            </div>
                        </Col>
                    </Row> */}

                    <Row>
                        <Col xs={12}>

                            <div className="job-application-container">

                                <div className="backdrop">
                                    <img src={Backdrop} className="img-fluid"></img>
                                </div>


                                <div className="content">
                                    {/* <img src={CareerFormImg} alt="" className="img-fluid" /> */}
                                    <p>
                                        Innovate, <br /> collaborate, and <br /> drive professional <br /> growth
                                    </p>
                                </div>

                                <div className="job-application-form">
                                    <form className="">
                                        <div className="form-field">
                                            <input type="text" name="name" placeholder="Full Name" autoComplete="off" value={name} onChange={namehandler}></input>
                                            <small>{nameError}</small>
                                        </div>
                                        <div className="form-field">
                                            <input type="text" name="number" placeholder="Phone no." autoComplete="off" value={mobileNumber} minLength="10" maxLength="10" onChange={mobileNumberhandler} ></input>
                                            <small>{mobileError}</small>
                                        </div>
                                        <div className="form-field">
                                            <input type="text" name="email" placeholder="Email id" autoComplete="off" value={emailInput} onChange={emailHandler}></input>
                                            <small>{emailError}</small>
                                        </div>
                                        <div className="form-field select-field">
                                            <select name="selectedRole" value={selectRole} onChange={handlerRoleSelect} >
                                                <option value="">Role you are applying for</option>
                                                <option value="Data Processors"> Data Processors</option>
                                                <option value="Angular Ionic Developer ">Angular Ionic Developer </option>
                                                <option value="Customer Support – Finance">Customer Support – Finance</option>
                                                <option value="Executive /Sr.Executive / Asst. Manager - Advertising">Executive /Sr.Executive / Asst. Manager - Advertising</option>
                                                <option value="Financial Data Analysts">Financial Data Analysts</option>
                                                <option value="Marketing Executives">Marketing Executives</option>
                                                <option value="PHP Developer ">PHP Developer </option>
                                                <option value="Project Manager - IT">Project Manager - IT</option>
                                                <option value="React Developer ">React Developer </option>
                                                <option value="Senior Client Relationship Executives">Senior Client Relationship Executives</option>
                                                <option value="Senior Delphi Developer">Senior Delphi Developer</option>
                                                <option value="SEO/SMM Executive">SEO/SMM Executive</option>
                                                <option value="Server/Desktop Support Engineer">Server/Desktop Support Engineer</option>
                                                <option value="Software Developer">Software Developer</option>
                                                <option value="Software Testing Engineer">Software Testing Engineer</option>
                                                <option value="Space Marketing Executive: Print &amp; online media">Space Marketing Executive: Print &amp; online media</option>
                                                <option value="SQL Developer / DBA Professionals">SQL Developer / DBA Professionals</option>
                                                <option value="Sr. Executives, Data Process &amp; Dissemination">Sr. Executives, Data Process &amp; Dissemination</option>
                                                <option value="System Administrator">System Administrator</option>
                                                <option value="Web Designer">Web Designer</option>
                                            </select>
                                            <small>{selectRoleError}</small>
                                        </div>
                                        <div className="form-field select-field">
                                            <select name="selectedLocation" value={selectLocation} onChange={handlerLocationSelect}>
                                                <option value="">Location</option>
                                                <option value="Ahmedabad">Ahmedabad</option>
                                                <option value="Bangalore">Bangalore</option>
                                                <option value="Chandigarh">Chandigarh</option>
                                                <option value="Chennai">Chennai</option>
                                                <option value="Delhi">Delhi</option>
                                                <option value="Hyderabad">Hyderabad</option>
                                                <option value="Jaipur">Jaipur</option>
                                                <option value="Kolkata">Kolkata</option>
                                                <option value="Lucknow">Lucknow</option>
                                                <option selected="selected" value="Mumbai">Mumbai</option>
                                                <option value="Mumbai, Chennai">Mumbai, Chennai</option>
                                                <option value="Mumbai, Delhi, Jaipur, Lucknow, Chandigarh, Ahmedabad ">Mumbai, Delhi, Jaipur, Lucknow, Chandigarh, Ahmedabad </option>
                                                <option value="Mumbai, Delhi, Kolkata, Bangalore, Chennai, Hyderabad, Ahmedabad">Mumbai, Delhi, Kolkata, Bangalore, Chennai, Hyderabad, Ahmedabad</option>
                                                <option value="Mumbai, Delhi, Kolkata, Bangalore, Chennai, Hyderabad,Ahmedabad">Mumbai, Delhi, Kolkata, Bangalore, Chennai, Hyderabad,Ahmedabad</option>
                                            </select>
                                            <small>{selectLocationError}</small>
                                        </div>
                                        {/* <div className="form-field uplode-field">
                                            <FileUploader
                                                multiple={false}
                                                handleChange={handleChange}
                                                name="file"
                                                types={fileTypes}
                                            />
                                            <span className="file-name">{fileName}</span>
                                            <small>{fileUplodeError}</small>
                                        </div> */}
                                        <div className="relative-sc">
                                        <div className="form-field submit-form-field">
                                            <input type="submit" value="Submit" onClick={handleSubmit} className="common-blue-btn"></input>
                                            
                                        </div>
                                        {dataSubmit && (<div className="submitted-data">Data submitted Successfully</div>)}
                                        </div>
                                        {/* <div>{data}</div> */}
                                        
                                    </form>
                                </div>
                            </div>

                        </Col>

                    </Row>



                </Container>
            </div>
        </>
    )
}

export default JobApplication;