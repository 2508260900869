import React ,{useEffect} from "react";
import CareerBanner  from "./CareerBanner/CareerBanner";
import Technologies from "./../HomePage/Technologies/Technologies"
import EmployeePerks from "./EmployeePerks/EmployeePerks";
import WhatWeLook from "./WhatWeLook/WhatWeLook";
import Journey from "./Journey/Journey";
import HiringProcess from "./HiringProcess/HiringProcess";
import DiscoverRole from "./DisciverRole/DisciverRole";
const Career = (props) =>{
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <CareerBanner heightPass={props.height}/>
            <EmployeePerks />
            <WhatWeLook />
            <Journey />
            <HiringProcess />
            <DiscoverRole />
            <Technologies />
        </>
    )
}
export default Career;