import React,{useEffect} from 'react';
import "./../AllProducts.css";
import AllProductsBanner from "../AllProductsBanner/AllProductsBanner";
import CMOTSekycBreadCrum from"./CMOTSekycBreadCrum/CMOTSekycBreadCrum"
import CMOTSeKYCbody from "./CMOTSeKYCbody/CMOTSeKYCbody"
import CMOTSeKYCFeatures from "./CMOTSeKYCFeatures/CMOTSeKYCFeatures"

const CMOTSeKYC = (props) => {
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return (
  <> 
     <AllProductsBanner heightPass={props.height}/>
     <CMOTSekycBreadCrum />
     <CMOTSeKYCbody />
    <CMOTSeKYCFeatures />
  </>
    );
}

export default CMOTSeKYC;