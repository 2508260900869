import React ,{useEffect} from "react";
import AboutBanner from "./AboutBanner/AboutBanner";
import OurCompany from "./OurCompany/OurCompany";
import LetsTalk from "./LetsTalk/LetsTalk";
import WeAre from "./WeAre/WeAre";
import TheFuture from "./TheFuture/TheFuture";
import Glance from "./Glance/Glance";
import OurClients from "./OurClients/OurClients";
import Innovators from './Innovators/Innovators'

const AboutUs = (props) =>{
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <AboutBanner heightPass={props.height}/>
            <OurCompany/>
            <Glance />
            <Innovators />
            <OurClients />
            <TheFuture/>
            <WeAre/>
            <LetsTalk />
        </>
    )
}

export default AboutUs;