import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { CiCircleChevLeft } from "react-icons/ci";
import { CiCircleChevRight } from "react-icons/ci";
import { getApiUrl } from "../../../../App";
import AOS from 'aos';
import 'aos/dist/aos.css';
const MobileDevelopmentFeatures =() =>{
  const setApiUrl = useContext(getApiUrl);
  const [data, setData] = useState();

    const settings = {
      className: "slider",
      dots: false,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      prevArrow: <CiCircleChevLeft />,
      nextArrow: <CiCircleChevRight />,
      arrows: true,
      responsive: [
        {
          breakpoint: 1365,
          settings: {
            slidesToShow: 2,

          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,

          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,

          }
        },
      ]
  };

 
    useEffect(() =>{
      fetch(`${setApiUrl}/Servicesinnerfeatures/mobile`)
      .then(response => response.json())
      .then((data) => {
        setData(data.data)
      })
      AOS.init({
        once:'true',
        disable: function() {
        var maxWidth = 992;
        return window.innerWidth < maxWidth;
        }
     });
    },[setApiUrl])
    return(
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="feature-slider">
                            <h3>Features</h3>
                            {data && (
                            <Slider {...settings} className="common-slider">
                              {data && data.map(({Heading,Features},index) => (
                                <div className="feature-main-box" tabindex={index} data-index={index} key={index}>
                                  <span>0{index + 1}</span>
                                  <h4>{Heading}</h4>
                                  <p>{Features}</p>
                              </div>
                              ))}
                            </Slider>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default MobileDevelopmentFeatures;