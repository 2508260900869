import React ,{useEffect} from "react";
import "./../AllProducts.css";
import AllProductsBanner from "../AllProductsBanner/AllProductsBanner";
import APIBreadcrum from "./APIBreadcrum/APIBreadcrum";
import APIFeatures from "./APIFeatures/APIFeatures";
import APIbody from "./APIbody/APIbody";

const APIDataFeed = (props) =>{
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <AllProductsBanner heightPass={props.height}/>
            <APIBreadcrum />
            <APIbody />
            <APIFeatures />
        </>
    )
}
export default APIDataFeed;