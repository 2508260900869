import React,{useEffect} from "react";
import ServicesBanner from "./ServicesBanner/ServicesBanner";
import OurServices from "./OurServices/OurServices";

const ServicesOverview = (props) =>{
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <ServicesBanner heightPass={props.height}/>
            <OurServices />
        </>
    )
}

export default ServicesOverview;