import React ,{useEffect} from "react";
import "./../AllServices.css";
import AllServicesBanner from "../AllServicesBanner/AllServicesBanner";
import CustomizedSoftwareDevelopmentBody from "./CustomizedSoftwareDevelopmentBody/CustomizedSoftwareDevelopmentBody";
// import CustomizedSoftwareDevelopmentFeatures from "./CustomizedSoftwareDevelopmentFeatures/CustomizedSoftwareDevelopmentFeatures";
import CustomizedSoftwareDevelopmentFaq from "./CustomizedSoftwareDevelopmentFaq/CustomizedSoftwareDevelopmentFaq";
const CustomizedSoftwareDevelopment = (props) =>{
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <AllServicesBanner heightPass={props.height}/>
            <CustomizedSoftwareDevelopmentBody />
            {/* <CustomizedSoftwareDevelopmentFeatures /> */}
            <CustomizedSoftwareDevelopmentFaq />
        </>
    )
}
export default CustomizedSoftwareDevelopment;