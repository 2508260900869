import React, { useEffect } from 'react';
import "./../AllProducts.css";
import AllProductsBanner from "../AllProductsBanner/AllProductsBanner";
import VirtualPortfolioTrackerBreadCrume from "./VirtualPortfolioTrackerBreadCrume/VirtualPortfolioTrackerBreadCrume"
import VirtualPortfolioTrackerbody from"./VirtualPortfolioTrackerbody/VirtualPortfolioTrackerbody"
import VirtualPortfolioTrackerFeatures from "./VirtualPortfolioTrackerFeatures/VirtualPortfolioTrackerFeatures"

const VirtualPortfolioTracker = (props) => {
    useEffect(() =>{
        window.scroll(0,0)
    })
    return (
      <>
       <AllProductsBanner heightPass={props.height} />
       <VirtualPortfolioTrackerBreadCrume />
       <VirtualPortfolioTrackerbody />
       <VirtualPortfolioTrackerFeatures />
       </>
    );
}

export default VirtualPortfolioTracker;