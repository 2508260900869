import React from "react";
import ProductsBanner from "./ProductsBanner/ProductsBanner";
import OurProducts from "./OurProducts/OurProducts";

const ProductsOverview =(props) =>{
    return(
        <>
            <ProductsBanner heightPass={props.height}/>
            <OurProducts />
        </>
    )
}
export default ProductsOverview;