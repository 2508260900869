import {React, useEffect, useState, useContext} from 'react';
import { Container,Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getApiUrl } from "../../App";

function LeadingFuture() {
    const[data, getData] = useState("");
    const[SetLeadingFutImg, GetLeadingFutImg] = useState("")
    const[SetLeadingFutHeading, GetLeadingFutHeading] = useState("")
    const[SetLeadingFutDescription, GetLeadingFutDescription] = useState("")
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
        AOS.init({
            once:'true',
           disable: function() {
           var maxWidth = 992;
           return window.innerWidth < maxWidth;
           }
        });
    } , [])

useEffect(() => {
  fetch(`${setApiUrl}/leadingthefuture`)
  .then((response) => response.json())
  .then((data)=> {
    getData(data.data)
    GetLeadingFutImg(data.data[0].image)
    GetLeadingFutHeading(data.data[0].heading)
    GetLeadingFutDescription(data.data[0].description)
  })
}, [setApiUrl])
    return (
    <> 
  <Container> 
  <Row className="align-items-center industry-box">
                    <Col lg={4} md={4} xs={12} className="industry-build" data-aos="flip-left" data-aos-duration="1500">
                        <img src={SetLeadingFutImg} alt="industry" className="img-fluid" />
                    </Col>
                    <Col lg={8} md={8} xs={12}>
                    <div className="main-header no-space-head" data-aos="fade-left" data-aos-duration="1500">
                            <h2 className="text-left">{SetLeadingFutHeading}</h2>
                            <p className="text-left">{SetLeadingFutDescription}</p>
                            <a href="/Careers" className="common-blue-btn">Explore Career Opportunities</a>
                    </div>
                    </Col>
                </Row>
  </Container>
    </>
    );
}

export default LeadingFuture;