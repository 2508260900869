import React ,{useEffect} from "react";
import "./../AllServices.css";
import AllServicesBanner from "../AllServicesBanner/AllServicesBanner";
import MobileDevelopmentBody from "./MobileDevelopmentBody/MobileDevelopmentBody";
import MobileDevelopmentFeatures from "./MobileDevelopmentFeatures/MobileDevelopmentFeatures";
import MobileDevelopmentFaq from "./MobileDevelopmentFaq/MobileDevelopmentFaq";
const MobileDevelopment = (props) =>{
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <AllServicesBanner heightPass={props.height}/>
            <MobileDevelopmentBody />
            <MobileDevelopmentFeatures />
            <MobileDevelopmentFaq />
        </>
    )
}
export default MobileDevelopment;