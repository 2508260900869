import React , { useContext, useEffect, useState }from "react";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { getApiUrl } from "../../../../App";
import AOS from 'aos';
import 'aos/dist/aos.css';
const WebsiteDesignFaq = () =>{
    const setApiUrl = useContext(getApiUrl);
    const [data,setData] = useState();
    useEffect(()=>{
        fetch(`${setApiUrl}/ServicesInnerFaq/website`)
        .then(response => response.json())
        .then((data) => {
            setData(data.data);
            //console.log(data.data);
        })
        AOS.init({
            once:'true',
            disable: function() {
            var maxWidth = 992;
            return window.innerWidth < maxWidth;
            }
         });
    },[setApiUrl])
    return(
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="main-header sub-heading" >
                            <h2 className="text-left">FAQ’s</h2>
                        </div>
                        <div className="faq-sc" >
                        <Accordion defaultActiveKey="0">
                            {data && data.map(({Question,Answer},index) => (
                            <Accordion.Item eventKey={index}>
                                <Accordion.Header>{Question}</Accordion.Header>
                                <Accordion.Body>{Answer}</Accordion.Body>
                            </Accordion.Item>
                           ))}
                          
                           
                        </Accordion>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default WebsiteDesignFaq;