import React ,{useEffect , useState} from "react";
import "./AllServicesBanner.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AllServicesBanner = (props) =>{
    const [path,setPath] = useState();
    let spaceBreadcrum;
    useEffect(() => {
            var abc = window.location.pathname.split('/')[2];
            spaceBreadcrum = abc.replace(/([A-Z&])/g, ' $1').replace(/([-])/g," ");
            setPath(spaceBreadcrum);
            // console.log(abc)
            
        },[path])
    return(
        <>
            <div className="about-banner-sc allservice-banner" style={{paddingTop: props.heightPass}} >
                <Container>
                    <Row>
                        <Col>
                            <div className="banner-name">
                                <h3><Link to="/services">Our Services</Link> / <span>{path}</span></h3>
                            </div>
                            
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )

}
export default AllServicesBanner;