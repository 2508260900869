import {React, useEffect,useContext,useState} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Container, Row, Col } from 'react-bootstrap';
import './PrivacyPolicyBody.css'
import { getApiUrl } from '../../App';
function PrivacyPolicyBody() {
    const setApiUrl = useContext(getApiUrl);
    const[data,getData] = useState();
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
       fetch(`${setApiUrl}/privacypolicy`)
        .then((respons) => respons.json())
        .then((data) => {
          
            getData(data.data);
            //console.table(getData);
        })
    } , [setApiUrl])
    return (
     <Container>
        <Row>
            <Col xs={12}>
            <div className='PrivacyBodyHeading' data-aos="fade-up"   data-aos-duration="1000"> Privacy Policy </div>
            <div className='PrivacyBodyText' data-aos="fade-up"   data-aos-duration="1000">
            {data && data.map(({description},index) => (
                <p key={index}>{description}</p>
            ))}
            
            </div> 
            </Col>
        </Row>
     </Container>
    );
}

export default PrivacyPolicyBody;