import React,{useContext, useEffect, useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import products1 from "./../../../../Images/Home_products_CMOTS_eKYC.png"
import { getApiUrl } from "../../../../App";
import AOS from 'aos';
import 'aos/dist/aos.css'

function CmotsLMSFeatures() {
 const setApiUrl = useContext(getApiUrl);
 const[data, setData] = useState()
useEffect(() => {
    fetch(`${setApiUrl}/ProductsInner`)
    .then((response) => response.json())
    .then((data) => {
        setData(data.data)
    })
}, [setApiUrl])
useEffect(() => {
    AOS.init({
        once:'true',
        disable: function() {
        var maxWidth = 992;
        return window.innerWidth < maxWidth;
        }
     });
} , [])


    return (    
        <>
           <Container className="container-blue">
                <Row>
                    {data && data.slice(5,6).map(({image, Features1, Features2, Features3, Features4}, index) =>(
                        <>
                          <Col lg={6} sm={12} key={index}>
                        <img src={image} alt="products" className="img-fluid" />
                    </Col>
                    <Col lg={6} sm={12} className="products-features">
                        <h3>Features</h3>
                        <ul>
                            <li>{Features1}</li>
                            <li>{Features2}</li>
                            <li>{Features3}</li>
                            <li>{Features4}</li>                           
                        </ul>
                    </Col>
                        </>
                    ))}
                  
                </Row>
            </Container>
        </>
    );
}

export default CmotsLMSFeatures;





