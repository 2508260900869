import {React, useEffect, useState, useContext} from "react";
import "./WeAre.css";
import { Col, Container, Row } from "react-bootstrap";
import weAre from "./../../Images/weAre.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getApiUrl } from "../../App";

const WeAre = () => {
    const[data, SetData] = useState();
    const[GetInMarketNo, SetInMarketNo] = useState("");
    const[GetInMarketDiscp, SetInMarketDiscp] = useState("")
    const[GetInEmplyeeNo, SetInEmplyeeNo] = useState("")
    const[GetInEmplyeeDiscp, SetInEmplyeeDiscp] = useState("")
    const[GetInClientNo, SetInClientNo] = useState("")
    const[GetInClientDiscp, SetInClientDiscp] = useState("")
    const[GetInProjectNo, SetInProjectNo] = useState("")
    const[GetInProjectDiscp, SetInProjectDiscp] = useState("")
    const[image,setImage] = useState()
    const setApiUrl = useContext(getApiUrl);
    let getData;
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
  
    useEffect(()=> {
        fetch(`${setApiUrl}/OurCompany/We`)
        .then((response) => response.json())
        .then((data) => {
            getData = data.data;
            SetData(getData)
            SetInMarketNo(getData[2].heading);
            SetInMarketDiscp(getData[2].description);
            SetInEmplyeeNo(getData[0].heading);
            SetInEmplyeeDiscp(getData[0].description);
            SetInClientNo(getData[1].heading);
            SetInClientDiscp(getData[1].description);
            SetInProjectNo(getData[3].heading);
            SetInProjectDiscp(getData[3].description);
            setImage(getData[0].image);
        })
    }, [setApiUrl])

    return(
        <>
            <div className="we-are-main">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="main-header no-space-head"  data-aos="fade-up"   data-aos-duration="1000">
                                <h2>We Are Cmots Infotech</h2>
                                <p>At Cmots Infotech, we transcend boundaries, innovate with purpose, and drive success in <br/> the dynamic landscape of financial technology</p>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="weare-main-sc">
                                <div data-aos="fade-right"   data-aos-duration="1000">
                                    
                                    <div>
                                        <label>{GetInMarketNo}</label>
                                        <p>{GetInMarketDiscp}</p>
                                    </div>
                                    <div>
                                        <label>{GetInClientNo}</label>
                                        <p>{GetInClientDiscp}</p>
                                    </div>
                                </div>
                                <div data-aos="flip-left"   data-aos-duration="1000">
                                    <img src={image} alt="we are" className="img-fluid" />
                                </div>
                                <div data-aos="fade-left"   data-aos-duration="1000">
                                    <div>
                                        <label>{GetInEmplyeeNo}</label>
                                        <p>{GetInEmplyeeDiscp}</p>
                                    </div>
                                    <div>
                                        <label>{GetInProjectNo}</label>
                                        <p>{GetInProjectDiscp}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default WeAre;