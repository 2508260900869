import React,{useEffect, useState, useContext} from "react";
import { Col, Container, Row } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css'
import { getApiUrl } from "../../../../App";


const WealthWrapProductBody = () =>{
    const setApiUrl = useContext(getApiUrl);
    const[data, setData] = useState()
    useEffect(() => {
        AOS.init({
            once:'true',
            disable: function() {
            var maxWidth = 992;
            return window.innerWidth < maxWidth;
            }
         });
    } , [])
useEffect(() => {
fetch(`${setApiUrl}/ProductsInner`)
.then((response) => response.json())
.then((data) => {
    setData(data.data)
})
}, [setApiUrl])
    return(
        <>
            <Container>
                <Row>
                    {data && data.slice(1,2).map(({FullDescription1, FullDescription2, FullDescription3,FullDescription4,FullDescription5,FullDescription6,FullDescription7,FullDescription8},index)=> (
<>
<Col xs={12}>
                    <div className="DiscriptionText" key={index}>
                        <p>{FullDescription1}</p>
                        <p>{FullDescription2}</p>
                        <p>{FullDescription3}</p>
                        <p>{FullDescription4}</p>
                        <p>{FullDescription5}</p>
                        <p>{FullDescription6}</p>
                        <p>{FullDescription7}</p>
                        <p>{FullDescription8}</p>
                        <span>For more information visit our website - <a href="http://wealthwraps.com/" target="_blank">WealthWraps</a></span>
                    </div>
                    </Col>
</>
                    ))}
                  
                </Row>
            </Container>
        </>
    )
}
export default WealthWrapProductBody;