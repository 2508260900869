import React ,{useEffect} from "react";
import "./../AllServices.css";
import AllServicesBanner from "../AllServicesBanner/AllServicesBanner";
import WebsiteDesignDevelopmentBody from "./WebsiteDesignDevelopmentBody/WebsiteDesignDevelopmentBody";
import WebsiteDesignDevelopeFeatures from "./WebsiteDesignDevelopeFeatures/WebsiteDesignDevelopeFeatures";
import WebsiteDesignFaq from "./WebsiteDesignFaq/WebsiteDesignFaq";
const WebsiteDesignDevelopment = (props) =>{
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <AllServicesBanner heightPass={props.height}/>
            <WebsiteDesignDevelopmentBody />
            <WebsiteDesignDevelopeFeatures />
            <WebsiteDesignFaq />
        </>
    )
}
export default WebsiteDesignDevelopment;