import React, { useEffect, useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getApiUrl } from "../../../../App";
import AOS from 'aos';
import 'aos/dist/aos.css'

const IFAFeatures = () =>{
    const setApiUrl = useContext(getApiUrl);
    const[data, setData] = useState()
useEffect(() => {
    fetch(`${setApiUrl}/ProductsInner`)
    .then((response) => response.json())
    .then((data) => {
        setData(data.data)
    })
}, [setApiUrl])
useEffect(() => {
    AOS.init({
        once:'true',
        disable: function() {
        var maxWidth = 992;
        return window.innerWidth < maxWidth;
        }
     });
} , [])

    return(
        <>
            <Container className="container-blue">
                <Row>
                {data && data.slice(2,3).map(({image, Features1,Features2, Features3, Features4, Features5, Features6, Features7}, index) =>(
                    <>
                     <Col lg={6} sm={12} key={index}>
                        <img src={image} alt="products" className="img-fluid" />
                    </Col>
                    <Col lg={6} sm={12} className="products-features">
                        <h3>Features</h3>
                        <ul>
                            <li>{Features1}</li>
                            <li>{Features2}</li>
                            <li>{Features3}</li>
                            <li>{Features4}</li>
                            <li>{Features5}</li>
                            <li>{Features6}</li>
                            <li>{Features7}</li>
                        </ul>
                    </Col>
                    </>

                ))}
                   
                </Row>
            </Container>
        </>
    )
}
export default IFAFeatures;