import {React, useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Col, Container, Row } from "react-bootstrap";
import "./ProductsBanner.css";

const ProductsBanner = (props) =>{
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
    return(
        <>
            <div className="about-banner-sc product-overview-banner" style={{paddingTop: props.heightPass}} >
                <Container>
                    <Row>
                        <Col>
                            <div className="banner-name">
                                <h3>Our Products</h3>
                            </div>
                            <h2 className="banner-description">Explore CMOTS innovations,<br/>  delivering cutting-edge solutions <br/> tailored to elevate your business.</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ProductsBanner;