import React,{useEffect, useState, useContext} from "react";
import "./Journey.css";
import { Col, Container, Row } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getApiUrl } from "../../App";


const Journey = () =>{
    const[data, getData] = useState("")
    const[GetDiscriptionData, DiscriptionData] = useState("")
    const[getJourneyImages, JourneyImages] = useState("")
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
useEffect(() => {
    fetch(`${setApiUrl}/career/get`)
    .then((response) => response.json())
    .then((data) => {
        getData(data.data)
        DiscriptionData(data.data[0].description)
        JourneyImages(data.data[0].image)
    })

}, [setApiUrl])
    return(
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="journey-main">
                            <div class="main-header no-space-head"  data-aos="fade-up" data-aos-duration="800">
                                <h2 className="text-left">Get Ready To Start Your Cmots <br/>Infotech Journey.</h2>
                            </div>
                            <div className="journey-content">
                                <div data-aos="fade-right" data-aos-duration="800">
                                    <p>{GetDiscriptionData}</p>
                                    {/* <a href="#" className="common-blue-btn">Explore Career Opportunities</a> */}
                                </div>
                                <div className="img-journey" data-aos="zoom-in" data-aos-duration="800">
                                    <img src={getJourneyImages} alt="journey" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Journey;