import React ,{useEffect} from 'react';
import PrivancyPolicyBanner from './PrivancyPolicyBanner/PrivancyPolicyBanner'
import PrivacyPolicyBody from './PrivacyPolicyBody/PrivacyPolicyBody'
import ConnectWithUs from "../HomePage/ConnectWithUs/ConnectWithUs";

const PrivacyPolicy = (props) => {
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return (
        <div>
    <PrivancyPolicyBanner heightPass={props.height} />
    <PrivacyPolicyBody />
    <ConnectWithUs />
    
        </div>
    );
}

export default PrivacyPolicy;