import {React, useEffect} from "react";
import "./OurClients.css";
import { Col, Container , Row} from "react-bootstrap";
import client1 from "./../../Images/client-1.png";
import client2 from "./../../Images/client-2.png";
import client3 from "./../../Images/client-3.png";
import client4 from "./../../Images/client-4.png";
import client5 from "./../../Images/client-5.png";
import client6 from "./../../Images/client-6.png";
import client7 from "./../../Images/client-7.png";
import client8 from "./../../Images/client-8.png";
import client9 from "./../../Images/client-9.png";
import client10 from "./../../Images/client-10.png";
import client11 from "./../../Images/client-11.png";
import client12 from "./../../Images/client-12.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
const OurClients =() => {
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
    return(
        <>
            <div className="our-client-main" data-aos="fade-up"   data-aos-duration="1000">
            <Container>
                <Row>
                <Col xs={12}>
                    <div className="main-header no-space-head">
                        <h2>Our Clients</h2>
                        <p>Explore the success stories and experiences of those who have trusted us on <br/>their digital journey.</p>
                    </div>
                    <div className="our-client-logo">
                        <ul>
                            <li><img src={client1} alt="client-logo" className="img-fluid"/></li>
                            <li><img src={client2} alt="client-logo" className="img-fluid"/></li>
                            <li><img src={client3} alt="client-logo" className="img-fluid"/></li>
                            <li><img src={client4} alt="client-logo" className="img-fluid"/></li>
                            <li><img src={client5} alt="client-logo" className="img-fluid"/></li>
                            <li><img src={client6} alt="client-logo" className="img-fluid"/></li>
                            <li><img src={client7} alt="client-logo" className="img-fluid"/></li>
                            <li><img src={client8} alt="client-logo" className="img-fluid"/></li>
                            <li><img src={client9} alt="client-logo" className="img-fluid"/></li>
                            <li><img src={client10} alt="client-logo" className="img-fluid"/></li>
                            <li><img src={client11} alt="client-logo" className="img-fluid"/></li>
                            <li><img src={client12} alt="client-logo" className="img-fluid"/></li>
                        </ul>
                    </div>
                </Col>
                </Row>
            </Container>
            </div>
        </>
    )
}

export default OurClients;