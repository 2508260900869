import React, { useEffect } from "react";
import "./HomePage.css";
import ConnectWithUs from "./ConnectWithUs/ConnectWithUs";
import Faq from "./Faq/Faq";
import IndustryExperience from "./IndustryExperience/IndustryExperience";
import OurClients from "./OurClients/OurClients";
import Technologies from "./Technologies/Technologies";
import Banner from "./Banner/Banner";
import ProductsWeDeliver from "./ProductsWeDeliver/ProductsWeDeliver";
import Services from "./Services/Services";
import LeadingFuture from "./LeadingFuture/LeadingFuture"

const HomePage = (props) =>{
    //console.log("hello",props.abc)
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <Banner heightPass={props.height}/>
            <Services />
            <ProductsWeDeliver />
            <Technologies />
            <OurClients />
            <IndustryExperience/>
            <LeadingFuture />
            <Faq />
            <div id="connectElement">
                <ConnectWithUs />
            </div> 
        </>
    )
}

export default HomePage;