import React,{useEffect} from 'react';
import DisclamerBanner from "./DisclamerBanner/DisclamerBanner"
import DisclamerBodyText from "./DisclamerBodyText/DisclamerBodyText"
import ConnectWithUs from "../HomePage/ConnectWithUs/ConnectWithUs";

function Disclaimer(props) {
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return (
    <> 
    <DisclamerBanner heightPass={props.height}/>  
    <DisclamerBodyText /> 
    <ConnectWithUs />
    </>
    );
}

export default Disclaimer;