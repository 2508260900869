import {React, useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Col, Container, Row } from "react-bootstrap";
import "./ContactBanner.css";

const ContactBanner = (props) => {
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
    return(
        <>
            <div className="about-banner-sc contact-banner-sc" style={{paddingTop: props.heightPass}}>
                <Container>
                    <Row>
                        <Col>
                            <div className="banner-name">
                                <h3>Contact US</h3>
                            </div>
                            <h2 className="banner-description">Connect with Us: Turning Ideas into <br/>Reality – Your Design Journey Begins<br/> Here!</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default ContactBanner;