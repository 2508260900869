import React, { useEffect, useState ,useContext} from "react";
import { Container ,Row, Col} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getApiUrl } from "../../../../App";
import AOS from 'aos';
import 'aos/dist/aos.css';
const DataProviderBody = () =>{
    const[optionValue,setOptionValue] = useState('')
    const [path,setPath] = useState();
    const [data,setData] = useState();
    const setApiUrl = useContext(getApiUrl)
    const navigate = useNavigate()
    const serviceChangeHandler = (e) =>{
            setOptionValue(e.target.value);
    }
    useEffect(() => {
        var abc = window.location.pathname.split('/')[2];
        setPath(abc);
        if(optionValue){
            navigate(`/services/${optionValue}`)
        }
        else{

        }
        fetch(`${setApiUrl}/ServicesInner`)
        .then(response => response.json())
        .then((data) => {
            setData(data.data);
           // console.log(data.data)
        })
        AOS.init({
            once:'true',
            disable: function() {
            var maxWidth = 992;
            return window.innerWidth < maxWidth;
            }
         });
    },[optionValue,setApiUrl])
    return(
        <>
        <Container>
                <Row>
                    <Col>
                        <div className="breadcrum-list">
                        <div className="main-header">
                            <h2>Market Data Provider</h2>
                        </div>
                        <div className="product-list">
                            <select onChange={serviceChangeHandler} value={path}>
                                <option disabled>Select Services</option>
                                <option value="market-data-provider">Market Data Provider</option>
                                <option value="mobile-development" >Mobile Development</option>
                                <option value="customized-software-development">Customized Software Development</option>
                                <option value="website-design-and-development" >Website Design & Development</option>
                                <option value="website-hosting" >Website Hosting</option>
                                <option value="resource-staffing">Resource Staffing</option>
                            </select>
                        </div>
                        </div>
                    </Col>
                    <Col lg={8} sm={12}>
                            <div className="service-main-box">
                                {data && data.slice(1,2).map(({Overview1,Overview2,Overview3},index) => (
                                    <div key={index}>
                                        <p>{Overview1}</p>
                                        <p>{Overview2}</p>
                                        <p>{Overview3}</p>
                                    </div>
                                ))}
                                
                            </div>
                        </Col>
                        <Col lg={4} sm={12}>
                            {data && data.slice(1,2).map(({image},index) => (
                            <div className="service-main-img" key={index}>
                                <img src={image} alt="service" className="img-fluid" />
                            </div>
                             ))}
                        </Col>
                </Row>
        </Container>
        </>
    )
}
export default DataProviderBody;