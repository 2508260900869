import {React, useEffect, useState, useContext} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './LatsTalk.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getApiUrl } from "../../App";

function LatsTalk() {
    const[data, getData] = useState("")
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
useEffect(()=> {
    fetch(`${setApiUrl}/Contactus`)
    .then((Response) => Response.json())
    .then((data) => {
        getData(data.data)
    })
}, [setApiUrl])


    return (
  <>
    <div className="we-are-main"> 
    <Container>
        <Row>
        <Col xs={12}  data-aos="fade-up"   data-aos-duration="1000">
       <div className="main-header no-space-head">
       <h2> Hey there! </h2>
        <p>
        Reach out for personalized support. Let's connect and explore opportunities. Your inquiry matters to us. Contact us for assistance.
        </p>
       </div>
         </Col>
         </Row>
        <div className='DivMarginLeft'  data-aos="fade-right"   data-aos-duration="1000">
        <Row>
        {data && data.map(({heading, description, url}, index) =>(
            <Col xs={12} md={4} lg={4} key={index}>
            <div className='LatsBox'><ul>
            <li> {heading} </li>
            <li> {description} </li>
            <li> 
                {/* <a href={url}> Let’s Talk </a>  */}
                &nbsp;
                </li>
                </ul> </div>
            </Col>
            ))}
           
         </Row>
        </div>
        
    </Container>
    </div>
  </>
    );
}

export default LatsTalk;