import React, { useEffect, useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import products1 from "./../../../../Images/Home_products_IFATransact.png"
import { getApiUrl } from "../../../../App";
import AOS from 'aos';
import 'aos/dist/aos.css'

const IssueTrackingSystemFeatures = () =>{
    const setApiUrl = useContext(getApiUrl);
    const[data, setData] = useState()
useEffect(() => {
    fetch(`${setApiUrl}/ProductsInner`)
    .then((response) => response.json())
    .then((data) => {
        setData(data.data)
    })
}, [setApiUrl])
useEffect(() => {
    AOS.init({
        once:'true',
        disable: function() {
        var maxWidth = 992;
        return window.innerWidth < maxWidth;
        }
     });
} , [])

    return(
        <>
            <Container className="container-blue">
                <Row>
                {data && data.slice(8,9).map(({image, Features1,Features2, Features3,}, index) =>(
                    <>
                     <Col lg={6} sm={12} key={index}>
                        <img src={image} alt="products" className="img-fluid" />
                    </Col>
                    <Col lg={6} sm={12} className="products-features">
                        <h3>Features</h3>
                        <ul>
                            <li>{Features1}</li>
                            <li>{Features2}</li>
                            <li>{Features3}</li>                           
                        </ul>
                    </Col>
                    </>

                ))}
                   
                </Row>
            </Container>
        </>
    )
}
export default IssueTrackingSystemFeatures;