import {React , useEffect} from "react";
import "./Banner.css";
import { Col, Container, Row } from "react-bootstrap";
import diamondBanner from "./../../Images/diamond-banner.png";
import bannerImg2 from "./../../Images/banner-img-2.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from "react-slick";
// import BannerBg1 from "./../../Images/banner-bg.png";
const Banner = (props) =>{
    //console.log("hello2",props.xyz)
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
    const settings = {
        arrows:false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        cssEase: 'linear',
        pauseOnHover : false
    };
    return(
        <>
            <div className="banner-sc">
                
                <Slider {...settings} >
                        <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                            <div className="banner-one-bg">
                            {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                            <Container>
                                <Row>
                                    <Col>
                                        <img src={diamondBanner} alt="banner" className="img-fluid" />
                                        <div className="banner-content">
                                            <h1>Your Partner in Financial <br/> Growth with a Focus on <br/> <span>Quality</span></h1>
                                            <p>We specialize in transforming data into actionable insights, <br/>helping you navigate the complexities of finance with <br/>confidence</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            </div>
                        </div>
                        
                        <div className="banner-inner"  style={{paddingTop: props.heightPass}}>
                            <div className="banner-two-bg">
                            {/* <img src={BannerBg1} alt="Banner Backgroung" className="img-fluid" /> */}
                            <Container>
                                <Row>
                                    <Col>
                                        <img src={bannerImg2} alt="banner" className="img-fluid" />
                                        <div className="banner-content">
                                            <h1>For the Future, we build <br/> customer centric <br/> experiences  backed by <br/> <span>Creativity</span></h1>
                                            <p>Found what you are looking for?</p>
                                        </div>
                                </Col>
                                </Row>
                            </Container>
                            </div>
                        </div>
                </Slider>
                       
            </div>
        </>
    )
}

export default Banner;
