import React ,{useEffect, useState, useContext} from "react";
import "./HiringProcess.css";
import { Col, Container, Row } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getApiUrl } from "../../App";

const HiringProcess = () =>{
    const[data, getData] = useState("")
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
useEffect(() => {
    fetch(`${setApiUrl}/career/our`)
    .then((response) => response.json())
    .then((data) =>{
        getData(data.data)
    })
}, [setApiUrl])

    return(
        <>
        <Container>
            <Row>
                <Col xs={12}>
                    <div class="main-header no-space-head"  data-aos="fade-up" data-aos-duration="800">
                        <h2>Our hiring process</h2>
                        <p>We map competencies and also check for compatibility, to be sure you match the values we inherit.</p>
                    </div>
                    <div className="process-main">
                        <div className="hiring-content" data-aos="fade-up" data-aos-duration="800">
                            <p>Dive into transparency and efficiency with our hiring process. We prioritize your potential, aiming for a seamless journey that aligns talent with opportunity</p>
                        </div>
                        <div className="process-flow">
                            {data && data.map(({subheading, description, description1, description2, description3, description4}, index) => (
                                <div data-aos="zoom-in" data-aos-duration="800" key={index}>
                                <span>0{(index) + 1}</span>
                                <label>{subheading}</label>
                                <ul>
                                    <li>{description}</li>
                                    <li>{description1}</li>
                                    <li>{description2}</li>
                                    <li>{description3}</li>
                                    <li>{description4}</li>
                                </ul>
                                </div>
                            ))}
                         
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        
        </>
    )
}
export default HiringProcess;