import {React, useEffect, useState, useContext} from "react";
import "./OurCompany.css";
import { Col, Container, Row } from "react-bootstrap";
import { getApiUrl } from "../../App";
// import AboutIMg1 from './../../Images/company-1.png';

import AOS from 'aos';
import 'aos/dist/aos.css';
const OurCompany = () =>{
    const[Data, getData] = useState()
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])

useEffect(() => {
    fetch(`${setApiUrl}/OurCompany/our`)
    .then(response => response.json())
    .then((data) => getData(data.data))
}, [setApiUrl])

    return(
        <>
            <div className="company-sc">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="main-header" data-aos="fade-up" data-aos-duration="1000">
                                <h2 className="text-left">Our Company</h2>
                            </div>
                        </Col>
                        <div className="divSizeChange"> 
                        {Data && Data.map(({heading, description,image}, index) => (    
                            <div className="company-descp" data-aos="fade-up" data-aos-duration="1000">
                                <img src={image} alt="" className="img-fluid d-block mx-auto" />
                                <label>  {heading} </label>
                                <p> {description} </p>
                            </div>
                        ))}
                        </div>
                        
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default OurCompany;