import React, { useEffect } from 'react';
import "./../AllProducts.css";
import AllProductsBanner from "../AllProductsBanner/AllProductsBanner";
import CmotsLMSBreadCrum from "./CmotsLMSBreadCrum/CmotsLMSBreadCrum"
import CmotsLMSbody from "./CmotsLMSbody/CmotsLMSbody"
import CmotsLMSFeatures from "./CmotsLMSFeatures/CmotsLMSFeatures"

const CmotsLMS = (props) => {
    useEffect(() =>{
        window.scroll(0, 0)
    })
    return (
       <> 
       <AllProductsBanner heightPass={props.height} />
       <CmotsLMSBreadCrum />
       <CmotsLMSbody />
       <CmotsLMSFeatures />
       </>
    );
}

export default CmotsLMS;