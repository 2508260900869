import React ,{useEffect} from "react";
import "./../AllProducts.css";
import AllProductsBanner from "../AllProductsBanner/AllProductsBanner";
import IFABreadcrum from "./IFABreadCrum/IFABreadcrum";
import IFAProductBody from "./IFAProductBody/IFAProductBody";
import IFAFeatures from "./IFAFeatures/IFAFeatures";

const IFATransact = (props) =>{
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <AllProductsBanner heightPass={props.height}/>
            <IFABreadcrum />
            <IFAProductBody />
            <IFAFeatures />
        </>
    )
}
export default IFATransact;