import React ,{useEffect} from "react";
import "./../AllProducts.css";
import AllProductsBanner from "../AllProductsBanner/AllProductsBanner";
import WealthWrapBreadcrum from "./WealthWrapBreadCrum/WealthWrapBreadcrum";
import WealthWrapProductBody from "./WealthWrapProductBody/WealthWrapProductBody";
import WealthWrapFeatures from "./WealthWrapFeatures/WealthWrapFeatures";

const WealthWraps = (props) =>{
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <AllProductsBanner heightPass={props.height}/>
            <WealthWrapBreadcrum />
            <WealthWrapProductBody />
            <WealthWrapFeatures />
        </>
    )
}
export default WealthWraps;