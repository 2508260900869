import {React, useEffect, useState, useContext} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import AOS from 'aos';
import 'aos/dist/aos.css'
import './DisciverRole.css';
import { getApiUrl } from "../../App";

function DisciverRole() {
    const[data, getData] = useState("")
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
        AOS.init({
            once:'true',
            disable: function() {
            var maxWidth = 992;
            return window.innerWidth < maxWidth;
            }
         });
    } , [])
useEffect(() =>{
    fetch(`${setApiUrl}/roles`)
    .then((response) => response.json())
    .then((data) => {
        getData(data.data);
    })
}, [setApiUrl])
    return (
    <>
    <Container>
        <Row>
            <Col>
            <div className='main-header no-space-head'> 
            <h2> Discover the role that’s <br /> best for you </h2>
            <p> CMOTS Internet Technologies Pvt. Ltd. is looking for smart and committed professionals to be a <br /> part of a fast paced organization in IT industry.  </p>
            </div>

            <div className="careers-sc" data-aos="fade-up" data-aos-duration="1000">
<Accordion defaultActiveKey="0">
    {data && data.map(({Role,City,Responsibilities, Responsibilities1, Responsibilities2, Responsibilities3, Responsibilities4, Responsibilities5, Responsibilities6, Responsibilities7, Responsibilities8,Responsibilities9,Responsibilities10,Responsibilities11,Responsibilities12,Responsibilities13,Qualifications, Skills, Skills1, Skills2, Skills3, Skills4, Skills5, Skills6}, index) => (
        <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header><div className='CareerTable'> <h3> {Role} </h3> <span> {City} </span></div></Accordion.Header>
            <Accordion.Body>
           <div className='CareersDataTable'> 
            <p> 
                <b> Responsibilities </b>
                {Responsibilities} {Responsibilities1} {Responsibilities2}
                {Responsibilities3} {Responsibilities4} {Responsibilities5}
                {Responsibilities6} {Responsibilities7} {Responsibilities8}
                {Responsibilities9} {Responsibilities10} {Responsibilities11}
                {Responsibilities12} {Responsibilities13}
            </p>
            <p> 
                <b> Qualifications </b>
                {Qualifications}
            </p>
            <p> 
                <b> Skills </b>
                <span> </span>
               <span>{Skills}</span><span>{Skills1}</span><span>{Skills2}</span><span>{Skills3}</span><span>{Skills4}</span><span>{Skills5}</span><span>{Skills6}</span>
            </p>
            {/* <button> Apply Now </button> */}
            <a href='/career-form'>Apply Now</a>
           </div>
            </Accordion.Body>
            </Accordion.Item>
    ))}
  </Accordion>
                        </div>
            </Col>
        </Row>
    </Container>
    </>
    );
}

export default DisciverRole;