import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './MobileMenu.css'

function MobileMenu() {
    return (
    <>
<Container fluid className="MobileMenu MibileMenuHideSHow"> 
    <Row> 
        <Col> 
        <ul className='justify-content-between'> 
            <li> <a href="/about-us">About</a> </li>
            <li> Services 
                <span className='justify-content-center'>
                    <h6> <a href="/services" > Overview </a> </h6>
                    <h6><a href="/services/market-data-provider"> Market Data Provider </a> </h6>
                    <h6><a href="/services/mobile-development"> Mobile Development </a> </h6>
                    <h6><a href="/services/customized-software-development"> Customized Software Development </a> </h6>
                     <h6> <a href="/services/website-design-and-development" > Website Design & Development </a> </h6>
                   <h6> <a href="/services/website-hosting"> Website Hosting </a> </h6>
    
   
   
    <h6><a href="/services/resource-staffing"> Resource Staffing </a></h6>
                </span>
            </li>
            <li> Products 
            <span className='justify-content-center'>
                    <h6><a href="/products" >Overview</a></h6>
    <h6><a href="/products/api-datafeed" >API DataFeed</a></h6>
    <h6><a href="/products/wealthwraps">WealthWraps</a></h6>
    <h6><a href="/products/ifa-transact">IFA Transact</a></h6>
    <h6><a href="/products/cmots-ekyc">CMOTS eKYC</a></h6>
    <h6><a href="/products/ipo-online">CMOTS IPO Online</a></h6>
    <h6><a href="/products/cmots-lms">CMOTS LMS</a></h6>
    <h6><a href="/products/sales-crm">Sales CRM</a></h6>
    <h6><a href="/products/virtual-portfolio-tracker">Virtual Portfolio Tracker</a></h6>
    <h6><a href="/products/issue-tracking">Issue Tracking system</a></h6>
                </span>
            </li>
            <li> <a href="/contact-us">Contact</a> </li>
        </ul>
        </Col>
    </Row>
</Container>
    </>
    );
}

export default MobileMenu;