import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Col, Container, Row } from "react-bootstrap";
import { useEffect } from 'react';
import './PrivancyPolicyBanner.css';

const PrivancyPolicyBanner = (props) => {
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
    return(
        <>
            <div className="about-banner-sc contact-banner" style={{paddingTop: props.heightPass}} >
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="banner-name">
                                <h3>Privacy Policy</h3>
                            </div>
                            <h2 className="banner-description"> 
                            Learn about our commitment to secure <br /> data handling in our straightforward <br /> privacy policy.
                            </h2>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default PrivancyPolicyBanner;