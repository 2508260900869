import React,{useEffect} from "react";
import "./ContactUs.css";
import ContactBanner from "./ContactBanner/ContactBanner";
import ConnectWithUs from "../HomePage/ConnectWithUs/ConnectWithUs";
import LatsTalk from "./LatsTalk/LatsTalk";
import CompanyAddress from "./CompanyAddress/CompanyAddress"

const ContactUs = (props) =>{
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return(
        <>
            <ContactBanner heightPass={props.height}/>
            <LatsTalk />
            <CompanyAddress />
            <ConnectWithUs />
            {/* <PeopleSay /> */}
        </>
    )
}

export default ContactUs;