import React,{useEffect} from 'react';
import "./../AllProducts.css";
import AllProductsBanner from "../AllProductsBanner/AllProductsBanner";
import ComtsIPOOnlineBearCrum from "./ComtsIPOOnlineBearCrum/ComtsIPOOnlineBearCrum";
import CmotsIPOOnlinebody from "./CmotsIPOOnlinebody/CmotsIPOOnlinebody";
import CmotsIPOOnlineFeatures from './CmotsIPOOnlineFeatures/CmotsIPOOnlineFeatures'


const CmotsIPOOnline = (props) => {
    useEffect(() =>{
        window.scroll(0 , 0)
    })
    return (       
    <>
      <AllProductsBanner heightPass={props.height}/>
      <ComtsIPOOnlineBearCrum />
      <CmotsIPOOnlinebody />
      <CmotsIPOOnlineFeatures />
    </>
       
    );
}

export default CmotsIPOOnline;