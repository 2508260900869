import {React, useEffect, useState, useContext} from "react";
import "./TheFuture.css";
import { Col, Container, Row } from "react-bootstrap";
import { getApiUrl } from "../../App";
import AOS from 'aos';
import 'aos/dist/aos.css';


const TheFuture = () =>{
    const[data, getData] = useState();
    const[image,setImage] = useState();
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])

    useEffect(() => {
        fetch(`${setApiUrl}/OurCompany/Crafting`)
        .then((response) => response.json())
        .then((data) => {
            getData(data.data)
            setImage(data.data[0].image)
        })
    }, [setApiUrl])
    return(
        <>
            <Container  data-aos="fade-up"   data-aos-duration="1000">
                <Row>
                    <Col xs={12}>
                        <div className="main-header">
                            <h2>Crafting Future With Innovation</h2>
                        </div>
                    </Col>
                    <Col lg={5} md={4} xs={12}>
                        <div className="future-img">
                            <img src={image} alt="" className="img-fluid" />
                        </div>
                 
                    </Col>
                    <Col lg={7} md={8} xs={12}>
                        <div className="future-content">
                        {data && data.map(({heading, description},index)=>(
                        <div key={index}>
                        <label>{heading}</label>
                        <p>{description}</p>
                        </div>  
                        ))}
                             
                            
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TheFuture;