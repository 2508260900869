import React, {useEffect, useState, useContext} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getApiUrl } from "../../../../App";

import AOS from 'aos';
import 'aos/dist/aos.css'


const IssueTrackingSystembody = () =>{
    const[data, setData] = useState()
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
        fetch(`${setApiUrl}/ProductsInner`)
        .then((response) => response.json())
        .then((data) => {
            setData(data.data)
        })
    }, [setApiUrl])
    useEffect(() => {
        AOS.init({
            once:'true',
            disable: function() {
            var maxWidth = 992;
            return window.innerWidth < maxWidth;
            }
         });
    } , [])
    return(
        <>
            <Container>
                <Row>
                    {data && data.slice(8,9).map(({FullDescription1, FullDescription2, FullDescription3},index) => (<>
                    <Col xs={12} key={index}>
                    <div className="DiscriptionText">
                        <p>{FullDescription1}</p>
                        <p>{FullDescription2}</p>
                        <span>For more information visit our website - <a href="http://supportdesk.cmots.com/" target="_blank"> Issue Tracking system - Tickets</a></span>
                    </div>
                    </Col></>
                    ))}                   
                </Row>
            </Container>
        </>
    )
}
export default IssueTrackingSystembody;