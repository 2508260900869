import React,{useEffect, useState, useContext} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getApiUrl } from "../../../../App";
import AOS from 'aos';
import 'aos/dist/aos.css'

function CmotsLMSbody() {
 const setApiUrl = useContext(getApiUrl);
 const[data, setData] = useState()
useEffect(() => {
    fetch(`${setApiUrl}/ProductsInner`)
    .then((response) => response.json())
    .then((data) => {
        setData(data.data)
    })
}, [setApiUrl])
useEffect(() => {
    AOS.init({
        once:'true',
        disable: function() {
        var maxWidth = 992;
        return window.innerWidth < maxWidth;
        }
     });
} , [])
    return (
      <>
       <Container>
                <Row>
                    <Col xs={12}>
                        {data && data.slice(5,6).map(({FullDescription1, FullDescription2, FullDescription3, FullDescription4},index)=> (
                        <>
                        <div className="DiscriptionText" key={index}>
                    <p>{FullDescription1}</p>
                    <p>{FullDescription2}</p>
                    <p>{FullDescription3}</p>
                    <p>{FullDescription4}</p>
                    <span>For more information visit our website - <a href="http://lms.cmots.com/" target="_blank">CMOTS LMS</a></span>
                    </div>
                        </>
                        ))}                    
                    </Col>
                </Row>
            </Container>
      </>
    );
}

export default CmotsLMSbody;