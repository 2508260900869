import React, { useEffect, useState, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css'
import { getApiUrl } from "../../../../App";

const WealthWrapFeatures = () =>{
    const[data, setData] = useState()
    const setApiUrl = useContext(getApiUrl);
useEffect(() =>{
 fetch(`${setApiUrl}/ProductsInner`)
 .then((response) => response.json())
 .then((data) => {
    setData(data.data)
 })
}, [setApiUrl])
useEffect(() => {
    AOS.init({
        once:'true',
        disable: function() {
        var maxWidth = 992;
        return window.innerWidth < maxWidth;
        }
     });
} , [])
    return(
        <>
            <Container className="container-blue">
                <Row className="align-items-center">
                    {data && data.slice(1,2).map(({image, Features1, Features2, Features3, Features4, Features5, Features6, Features7, Features8, Features9}, index) =>(
                        <>
                    <Col lg={6} sm={12} key={index}>
                        <img src={image} alt="products" className="img-fluid" />
                    </Col>
                    <Col lg={6} sm={12} className="products-features">
                        <h3>{Features1}</h3>
                        <ul>
                            <li>{Features2}</li>
                            <li>{Features3}</li>
                            <li>{Features4}</li>
                        </ul>
                        <h3>{Features5}</h3>
                        <ul>
                            <li>{Features6}</li>
                            <li>{Features7}</li>
                        </ul>
                        <h3>{Features8}</h3>
                        <ul>
                            <li>{Features9}</li>
                        </ul>
                    </Col>
                        </>
                    ))}
                    
                </Row>
            </Container>
        </>
    )
}
export default WealthWrapFeatures;