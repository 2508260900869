import React from "react";
import "./LetsTalk.css";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const LetsTalk = () => {
    const navigate = useNavigate();
    const changeHandler =() =>{
        let connectElement = localStorage.getItem('connect')
        let path = "/";
        navigate(path);
        setTimeout(() => {
            window.scrollTo({
                top: document.getElementById(connectElement).offsetTop - document.getElementsByClassName('header-sc')[0].offsetHeight,
                behavior: 'smooth',
            });
        }, 700);
    }
    return(
        <>
            <div className="talk-sc">
                <Container>
                    <Row>
                        <Col xs={12} className="text-center">
                            <h2>We transform your ideas into action. Let's collaborate and bring your vision to life!</h2>
                            <a onClick={changeHandler}>Let’s Talk</a>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default LetsTalk;