import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getApiUrl } from "../../App";


function Innovators() {
    const [data, getData] = useState()
    const[image,setImage] = useState()
    const setApiUrl = useContext(getApiUrl);
    AOS.init({
        once:'true',
        disable: function() {
        var maxWidth = 992;
        return window.innerWidth < maxWidth;
        }
     });
useEffect(() => {
 fetch(`${setApiUrl}/OurCompany/innovators`)
 .then((response) => response.json())
 .then((data) => {
    getData(data.data)
    setImage(data.data[0].image)
 })
}, [setApiUrl])

    return (
    <>
   <Container> 
   <Row className="align-items-center">
                    <Col lg={5} md={6} xs={12} data-aos="fade-right"   data-aos-duration="1000">
                        <div className="innovators-main">
                           
                            <div >
                                <img src={image} alt="" className="img-fluid" />
                            </div>
                     
                            
                        </div>
                    </Col>
                    <Col lg={7} md={6} xs={12} data-aos="fade-left"   data-aos-duration="1000">
                       {data && data.map(({heading,description}, index) =>(
                        <div className="innovators-main-content" key={index}>
                        <h3>{heading}</h3>
                        <p>{description}</p>
                        </div>
                       ))}
                       
                    </Col>
                </Row>
   </Container>
    </>
    );
}

export default Innovators;