import {React, useEffect, useState, useContext}from "react";
import "./EmployeePerks.css";
import { Col, Container, Row } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
import arrowJoin from "./../../Images/join.png";
import { getApiUrl } from "../../App";
import { Link } from "react-router-dom";
const EmployeePerks = () =>{
    const[data, getData] = useState("")
    const[SetDiscriptiotnData, DiscriptiotnData] = useState("")
    const setApiUrl = useContext(getApiUrl);
    useEffect(() => {
        AOS.init({
        once:'true',
          disable: function() {
          var maxWidth = 992;
          return window.innerWidth < maxWidth;
          }
       });
    } , [])
useEffect(() => {
    fetch(`${setApiUrl}/career/employee`)
    .then((response) => response.json())
    .then((data)=> {
        getData(data.data)
        DiscriptiotnData(data.data[0].description);
    })
}, [setApiUrl])

    return(
        <>
            <div className="employeeperk-sc">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="main-header" data-aos="fade-up" data-aos-duration="1000">
                            <h2 className="text-left">Employee Perks</h2>
                        </div>
                    </Col>
                    <Col lg={4} md={12} xs={12}>
                        <div className="employeeperk-descp" data-aos="fade-up" data-aos-duration="1000">
                            <p>{SetDiscriptiotnData}</p>
                        </div>
                    </Col>
                    <Col lg={8} md={12} xs={12}>
                        <div className="employeeperk-box-main">
                            {data && data.map(({subheading, image},index) =>(
                             <div key={index} data-aos="flip-left" data-aos-duration="800">
                                <img src={image} alt="perks" className="img-fluid" />
                                <label>{subheading}</label>
                            </div>
                            ))}                              
                             <div className="join-us-sc" data-aos="flip-left" data-aos-duration="800">
                                <Link to="./../career-form">
                                <img src={arrowJoin} alt="perks" className="img-fluid" />
                                <label>Join Us</label>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    )
}
export default EmployeePerks;