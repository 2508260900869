import  {React, useState} from "react";
import "./FeedbackFormBanner.css";
import { Col, Container, Row } from "react-bootstrap";
import AOS from 'aos';
import 'aos/dist/aos.css';
const FeedbackFormBanner = (props) => {
    

    return (
        <>
            {/* <div style={{ paddingTop: props.height }} >
                <img src={CareerBannerImg} className="img-fluid"></img>
            </div> */}
            <div className="feedback-form-banner-sc" style={{ paddingTop: props.heightPass }} >
                <Container>
                    <Row>
                        <Col>
                            <div className="banner-name">
                                <h3>Feedback</h3>
                            </div>
                            <h2 className="banner-description">Share your experience; your feedback <br/> fuels CMOTS' commitment to continuous <br/> improvement and excellence.</h2>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default FeedbackFormBanner;