import { React, useRef, useState, useEffect, useContext } from "react";
import "./Services.css";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { CiCircleChevLeft } from "react-icons/ci";
import { CiCircleChevRight } from "react-icons/ci";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getApiUrl } from "../../App";
import { Link } from "react-router-dom";
const Services = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const [activeSlide, setActiveSlide] = useState(0);
    const [data,getData] = useState();
    const setApiUrl = useContext(getApiUrl);
    const slider = useRef();

    useEffect(() => {
        
         scrollToElement()

         AOS.init({
            once:'true',
            disable: function() {
            var maxWidth = 992;
            return window.innerWidth < maxWidth;
            }
         });
         fetch(`${setApiUrl}/OurServices`)
         .then(response => response.json())
         .then(data => {
            getData(data.data)
            //console.log(data);
         })
    }, [slideIndex,activeSlide,setApiUrl]);
    
    const scrollToElement = () => {
    
        // document.querySelector(".services-main-sc .active").scrollIntoView({ behavior: 'smooth' ,block: "nearest", inline: "nearest"});
      
    };
  
    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CiCircleChevLeft />,
        nextArrow: <CiCircleChevRight />,
        afterChange: current => setActiveSlide(current)
    };
    const onClick = (index) => {
        setSlideIndex(index)
        slider.current.slickGoTo(index);
    }
    return (
        <>
            <Container className="services-sc">
                <Row>
                    <Col xs={12}>
                        <div className="main-header no-space-head">
                            <h2>Our Services</h2>
                            <p>Explore the array of technologies that power our solutions. Discover how we harness <br /> innovation to drive success.</p>
                        </div>
                        <div className="services-main-sc" >
                            <div data-aos="fade-right" data-aos-duration="1000">
                                <ul>
                                    {data && data.map(({services},index) => (
                                         <li className={activeSlide  == index ? "active" : ""}   onClick={() => onClick(index)}>{services}</li>
                                    ))}
                                </ul>
                            </div>
                            <div data-aos="fade-left" data-aos-duration="1500">
                                <Slider {...settings} ref={slider} className="common-slider">
                                    {data && data.map(({services,description,knowMore},index) => (
                                        <div className="slider-inner" tabindex={index} data-index={index} key={index}>
                                        <h3>{services}</h3>
                                        <p>{description}</p>
                                        <Link to={knowMore} className="common-blue-btn white-common-btn">Know More</Link>
                                    </div>
                                    ))}
                                    
                                </Slider>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Services;